import { Button } from "@musicaudienceexchange/melodies/Button";
import { SvgMail } from "@musicaudienceexchange/melodies/Svgs/Mail";
import { Form, FormikTextInput } from "@musicaudienceexchange/melodies/forms";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import { LOGIN_ROUTE } from ".";
import { emailField, useLogin } from "../common/useLogin";

import { useHistoryPush } from "components/common";
import {
  Description,
  Link,
  SetLogo,
  PageLayout,
} from "components/common/PageLayout";
import styled from "styled-components";

const Container = styled(Div)`
  padding: 40px 48px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;

const Header = styled(Div)`
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 32px;
  }
`;

const Login: React.FC = () => {
  const historyPush = useHistoryPush();

  const {
    isSubmitButtonDisabled,
    setIsSubmitButtonDisabled,
    onSubmitForm,
    emailSentUI,
  } = useLogin(false);

  return (
    <PageLayout size={476} light={false} header={false}>
      <Container>
        <Header flexColumn alignCenter>
          <SetLogo />
          <Description textAlignCenter>
            Enter the email address associated with your SET account and you’ll
            receive a one-time login link.
          </Description>
        </Header>
        <Form fields={[emailField]} onSubmit={onSubmitForm} w100>
          <FormikTextInput
            label="Email Address"
            withDebounce={false}
            leftIcon={<SvgMail />}
            name="email"
            placeholder="Email..."
          />
          {emailSentUI}
          <SubmitButton
            isButtonDisabled={isSubmitButtonDisabled}
            setIsButtonDisabled={setIsSubmitButtonDisabled}
          />
        </Form>
        <Div mt={24} justifyCenter w100>
          <Div semiBold>
            <Link onClick={() => historyPush(LOGIN_ROUTE)}>
              Log In with Password
            </Link>
          </Div>
        </Div>
      </Container>
    </PageLayout>
  );
};

const SubmitButton: React.FC<{
  isButtonDisabled: boolean;
  setIsButtonDisabled: (isButtonDisabled: boolean) => unknown;
}> = ({ isButtonDisabled, setIsButtonDisabled }) => {
  const { values: email, submitForm } = useFormikContext();

  useEffect(() => {
    setIsButtonDisabled(false);
  }, [email]);

  return (
    <Div spaceBetween mt={30} mb={10}>
      <Button
        isDisabled={isButtonDisabled}
        variant="primary"
        onClick={submitForm}
      >
        Log In
      </Button>
    </Div>
  );
};

export default Login;
