import styled from "styled-components";
import { Footer } from "components/common/Footer";
import { useQueryParams, useIsMobile } from "hooks";
import { SetLiveIcon } from "./icons/SETLive";
import { TextInput } from "@musicaudienceexchange/melodies/TextInput";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Button } from "@musicaudienceexchange/melodies/Button";
import {
  cancelOptOut,
  resendOptOutEmail,
  requestOptOut,
  verifyOptOut,
} from "./optOutActions";
import HeaderBG from "./icons/HeaderBG";
import CheckIcon from "./icons/CheckIcon";
import EmailIcon from "./icons/EmailIcon";
import { Spinner } from "@musicaudienceexchange/melodies/Spinner";

type SetLiveStatus =
  | "initial"
  | "submittingEmail"
  | "emailSubmitted"
  | "emailResending"
  | "emailResent"
  | "confirmDelete"
  | "submittingConfirmation"
  | "deleteConfirmed"
  | "cancelConfirmed";

const OptOut = () => {
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState<SetLiveStatus>("initial");
  const [error, setError] = useState("");

  const isMobile = useIsMobile();

  const { code } = useQueryParams(["code"]);

  // if confirmation code in query params, set verification flow
  useEffect(() => {
    if (code) {
      setStatus("confirmDelete");
    }
  }, [code]);

  const onSubmitEmail = async (email: string) => {
    setError("");
    setStatus("submittingEmail");

    try {
      const { status, message } = await requestOptOut(email);

      if (status === "failure") {
        throw new Error(message);
      }

      setStatus("emailSubmitted");
    } catch (error) {
      setError((error as Error).message);
      setStatus("initial");
    }
  };

  const onResendEmail = async (email: string) => {
    setError("");
    setStatus("emailResending");

    try {
      const { status, message } = await resendOptOutEmail(email);

      if (status === "failure") {
        throw new Error(message);
      }

      setStatus("emailResent");
    } catch (error) {
      setError((error as Error).message);
      setStatus("emailSubmitted");
    }
  };

  const onChangeEmailRequest = () => {
    setError("");
    setEmail("");
    setStatus("initial");
  };

  const onCancelRequest = async (code: string) => {
    setError("");
    setEmail("");
    setStatus("submittingConfirmation");

    try {
      const { status, message } = await cancelOptOut(code);

      if (status === "failure") {
        throw new Error(message);
      }

      setStatus("cancelConfirmed");
    } catch (error) {
      setStatus("confirmDelete");
      setError((error as Error).message);
    }
  };

  const onVerifyDelete = async (code: string) => {
    setError("");
    setStatus("submittingConfirmation");

    try {
      const { status, message } = await verifyOptOut(code);

      if (status === "failure") {
        throw new Error(message);
      }

      setStatus("deleteConfirmed");
    } catch (error) {
      setError((error as Error).message);
      setStatus("confirmDelete");
    }
  };

  return (
    <PageContainer>
      {!isMobile && <DesktopNavBar />}
      <OptOutContent
        email={email}
        error={error}
        onCancelRequest={() => onCancelRequest(code)}
        onChangeEmailRequest={() => onChangeEmailRequest()}
        onResendEmail={() => onResendEmail(email)}
        onSubmitRequest={() => onSubmitEmail(email)}
        onVerifyDelete={() => onVerifyDelete(code)}
        setEmail={setEmail}
        status={status}
      />
      <MagnetFooter />
    </PageContainer>
  );
};

export default OptOut;

const MagnetFooter = styled(Footer)`
  position: absolute;
  bottom: 0;
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f7fafc;
  height: 100vh;

  & > * {
    font-family: Poppins;
  }
`;

const OptOutContent = ({
  email,
  error,
  setEmail,
  onCancelRequest,
  onChangeEmailRequest,
  onResendEmail,
  onSubmitRequest,
  onVerifyDelete,
  status,
}: {
  email: string;
  error: string;
  setEmail: Dispatch<SetStateAction<string>>;
  onCancelRequest: () => void;
  onChangeEmailRequest: () => void;
  onResendEmail: () => void;
  onSubmitRequest: () => void;
  onVerifyDelete: () => void;
  status: SetLiveStatus;
}) => {
  const isMobile = useIsMobile();

  switch (status) {
    case "initial":
    case "submittingEmail":
      return (
        <>
          <HeaderContainer height={isMobile ? "164px" : "227px"}>
            <BGContainer>
              <HeaderBG isMobile={isMobile} />
            </BGContainer>
            {isMobile && (
              <SetLiveContainer>
                <SetLiveIcon />
              </SetLiveContainer>
            )}
            <HeaderTextContainer
              style={{ width: "100%", paddingTop: isMobile ? 0 : 40 }}
            >
              <HeaderText color="#FFF">Want to delete your data?</HeaderText>
            </HeaderTextContainer>
          </HeaderContainer>
          <BodyContainer isMobile={isMobile}>
            <>
              <BodyText>
                Your privacy is important to us. If you would like us to remove
                any of your personal information we may have, please let us know
                which email address you have used on SET. We'll send you an
                email to that address with a link that will verify your email
                and start the process of deleting any personal information we
                might have of yours.
              </BodyText>
              <br />
              <br />
              {status === "submittingEmail" ? (
                <Spinner />
              ) : (
                <TextInput
                  value={email}
                  onChange={(text) => setEmail(text)}
                  placeholder="john@email.com"
                  leftIcon={<EmailIcon />}
                  hasError={!!error}
                  helperText={error}
                />
              )}
              <br />
              <Button
                onClick={() => onSubmitRequest()}
                isDisabled={status === "submittingEmail" || !email}
              >
                Send Me An Email
              </Button>
            </>
          </BodyContainer>
        </>
      );
    case "emailSubmitted":
    case "emailResending":
    case "emailResent":
      return (
        <>
          <HeaderContainer
            height="auto"
            style={{ marginTop: isMobile ? 0 : 71 }}
          >
            {isMobile && (
              <SetLiveContainer style={{ marginBottom: 45 }}>
                <SetLiveIcon dark />
              </SetLiveContainer>
            )}
            <CheckIcon />
            <HeaderText color="#000" style={{ marginTop: 8 }}>
              Email sent successfully
            </HeaderText>
            <br />
            <BodyText>An email has been sent to {email}</BodyText>
          </HeaderContainer>
          <BodyContainer isMobile={isMobile}>
            <StatusBox>
              {status === "emailResending" ? (
                <Spinner />
              ) : (
                <BodyText>
                  Didn't get the email?{" "}
                  <LinkSpan onClick={() => onResendEmail()}>
                    Click here
                  </LinkSpan>{" "}
                  to resend it. Or{" "}
                  <LinkSpan onClick={() => onChangeEmailRequest()}>
                    click here
                  </LinkSpan>{" "}
                  to enter a different email address.
                </BodyText>
              )}
            </StatusBox>
            <br />
            {!!error && (
              <StatusText style={{ color: "red" }}>{error}</StatusText>
            )}
            {status === "emailResent" && (
              <StatusText>We've resent the email</StatusText>
            )}
          </BodyContainer>
        </>
      );
    case "confirmDelete":
    case "submittingConfirmation":
      return (
        <>
          <HeaderContainer
            height="auto"
            style={{ marginTop: isMobile ? 0 : 71 }}
          >
            {isMobile && (
              <SetLiveContainer style={{ marginBottom: 34 }}>
                <SetLiveIcon dark />
              </SetLiveContainer>
            )}
          </HeaderContainer>
          <BodyContainer isMobile={isMobile}>
            <ConfirmDeleteBox>
              <BodyText>
                Are you sure you want to continue with the deletion process?
              </BodyText>
              <br />
              <br />
              <Button
                isDisabled={status === "submittingConfirmation"}
                onClick={() => onVerifyDelete()}
              >
                Yes, I'm Sure
              </Button>
            </ConfirmDeleteBox>
            <br />
            <br />
            {status === "submittingConfirmation" ? (
              <Spinner />
            ) : (
              <StatusText>
                <LinkSpan onClick={() => onCancelRequest()}>
                  Click here
                </LinkSpan>{" "}
                to cancel your request
              </StatusText>
            )}
            {!!error && (
              <>
                <br />
                <StatusText style={{ color: "red" }}>{error}</StatusText>
              </>
            )}
          </BodyContainer>
        </>
      );
    case "deleteConfirmed":
    case "cancelConfirmed":
      return (
        <>
          <HeaderContainer
            height="auto"
            style={{ marginTop: isMobile ? 0 : 71 }}
          >
            {isMobile && (
              <SetLiveContainer style={{ marginBottom: 45 }}>
                <SetLiveIcon dark />
              </SetLiveContainer>
            )}
            <CheckIcon />
            <HeaderText color="#000" style={{ marginTop: 8 }}>
              Success!
            </HeaderText>
          </HeaderContainer>
          <BodyContainer isMobile={isMobile}>
            <ConfirmDeleteBox>
              <BodyText>
                {status === "deleteConfirmed"
                  ? "Your information has been successfully deleted from SET"
                  : "Your request was successfully canceled."}
              </BodyText>
              <DividerLine />
              {status === "deleteConfirmed" ? (
                <StatusText>
                  If you previously agreed to share your data through SET with
                  an artist or a third party, you'll need to send a request
                  directly to them to delete your data.
                </StatusText>
              ) : (
                <StatusText>
                  <LinkSpan
                    onClick={() => {
                      window.location.assign(
                        process.env.REACT_APP_VERIFY_REDIRECT
                      );
                    }}
                  >
                    Click here
                  </LinkSpan>{" "}
                  to return home.
                </StatusText>
              )}
            </ConfirmDeleteBox>
          </BodyContainer>
        </>
      );
  }
};

const DesktopNavBar = () => {
  return (
    <NavBar>
      <div style={{ display: "flex", alignItems: "center" }}>
        <div
          style={{ cursor: "pointer" }}
          onClick={() => {
            window.location.assign(process.env.REACT_APP_VERIFY_REDIRECT);
          }}
        >
          <SetLiveIcon dark />
        </div>
      </div>
    </NavBar>
  );
};

const BGContainer = styled.div`
  position: absolute;
  z-index: -1;
`;

const BodyContainer = styled.div<{ isMobile: boolean }>`
  text-align: center;
  padding: 0 22px;
  max-width: 410px;
  margin-top: ${({ isMobile }) => (isMobile ? "21px" : "45px")};
`;

const BodyText = styled.span<{ bold?: boolean }>`
  font-weight: ${({ bold }) => (bold ? 600 : 400)};
  font-size: 15px;
  line-height: 22px;
`;

const ConfirmDeleteBox = styled.div`
  background: #ffffff;
  border: 1px solid #999999;
  border-radius: 12px;
  padding: 30px 24px;
`;

const DividerLine = styled.div`
  border-top: 1px solid #999;
  margin: 25px 0;
`;

const HeaderContainer = styled.div<{ height: string }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: ${({ height }) => height};
  z-index: 1;
`;

const HeaderText = styled.header<{ color: string }>`
  font-weight: 600;
  font-size: 30px;
  line-height: 37px;
  text-align: center;
  color: ${({ color }) => color};
`;

const HeaderTextContainer = styled.div`
  text-align: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 92px;
  margin-right: 92px;
`;

const LinkSpan = styled.span`
  color: #006acc;
  cursor: pointer;
`;

const NavBar = styled.div`
  width: 100%;
  height: 68px;
  padding: 0 21px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-bottom: thin solid #97979744;
`;

const StatusBox = styled.div`
  background: #f1f6f9;
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 12px 14px;
`;

const StatusText = styled.div`
  font-size: 12px;
  line-height: 18px;
  font-weight: 600;
`;

const SetLiveContainer = styled.div`
  margin-top: 13px;
  display: flex;
  align-items: center;
`;
