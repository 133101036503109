import { sessionApi } from "@musicaudienceexchange/firebase-auth-ui";
import {
  Avatar as AvatarBase,
  AvatarProps,
} from "@musicaudienceexchange/melodies/Avatar";
import { Button } from "@musicaudienceexchange/melodies/Button";
import { SvgIndividual } from "@musicaudienceexchange/melodies/Svgs/Individual";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useUser } from "auth";
import { AppLoading } from "components/AppLoading";
import { useAuthQueryParams } from "components/common";
import {
  Description,
  Link,
  PageLayout,
  Title,
} from "components/common/PageLayout";
import {
  DocumentData,
  collection,
  doc,
  query,
  where,
} from "firebase/firestore";
import coverBackgroundImage from "images/cover-background.jpg";
import { useFirebase } from "providers/FirebaseProvider";
import React, { useEffect, useState } from "react";
import {
  useCollectionData,
  useDocumentData,
} from "react-firebase-hooks/firestore";
import styled from "styled-components";
import { ContactInfoForm } from "./ContactInfoForm";
import { NewPasswordFormModal } from "./PasswordForm";

const useAuthRedirect = (
  profile: DocumentData,
  artistGroups: DocumentData[],
  artistSignups: DocumentData[]
) => {
  const { clearSession, claims } = useUser();
  const { redirect: redirectUrl, signOut } = useAuthQueryParams([
    "redirect",
    "signOut",
  ]);
  const [shouldRedirect, setShouldRedirect] = useState(true);

  useEffect(() => {
    (async () => {
      if (signOut === "true") clearSession();

      if (!artistGroups.length && !artistSignups.length && !claims.isAdmin) {
        window.location.href = sessionApi.go(process.env.REACT_APP_SIGNUP_PATH);
        return;
      }

      if (redirectUrl && signOut !== "true") {
        window.location.href = sessionApi.go(decodeURIComponent(redirectUrl));
      } else {
        setShouldRedirect(false);
      }
    })();
  }, []);

  return shouldRedirect;
};

const MyAccount: React.FC = () => {
  const { firestore } = useFirebase();
  const { user } = useUser();

  const [profile, isLoadingProfile] = useDocumentData(
    doc(firestore, `profiles/${user.uid}`)
  );

  const [artistGroups, isLoadingArtistGroups] = useCollectionData(
    query(
      collection(firestore, "artist_groups"),
      where(`members.${user.uid}`, "!=", "")
    )
  );

  const [artistSignups, isLoadingArtistSignups] = useCollectionData(
    query(
      collection(firestore, "artist_signups"),
      where("uid", "==", user.uid),
      where("status", "==", "submitted")
    )
  );

  return (
    <>
      {isLoadingProfile || isLoadingArtistGroups || isLoadingArtistSignups ? (
        <AppLoading />
      ) : (
        <MyAccountWithData
          profile={profile}
          artistGroups={artistGroups}
          artistSignups={artistSignups}
        />
      )}
    </>
  );
};

const Cover = styled(Div)`
  height: 165px;
  margin-bottom: -165px;
  border-radius: 20px 20px 0 0;
  background: linear-gradient(
      0deg,
      rgba(27, 0, 118, 0.5) 0%,
      rgba(27, 0, 118, 0.5) 100%
    ),
    url(${coverBackgroundImage}) center / cover no-repeat, #190235;
  background-blend-mode: darken, normal, normal;
`;

const Container = styled(Div)`
  padding: 43px 0 74px 0;
  margin: 0 auto;
  max-width: 380px;

  h1 {
    color: white;
  }

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;

const Avatar = styled(AvatarBase)`
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  margin-top: 20px;
  width: 145px;
  height: 145px;

  ${(props) => props.theme.mediaQueries.mobile} {
    margin-top: 16px;
    width: 100px;
    height: 100px;
  }
`;

const MediaIcon = styled(SvgIndividual)`
  && {
    width: 70%;
    height: 70%;
    path {
      fill: ${(props) => props.theme.colors.black20};
    }
    ${(props) => props.theme.mediaQueries.mobile} {
      width: 22px;
      height: 22px;
    }
  }
`;

const Actions = styled(Div)`
  gap: 20px;
`;

const MyAccountWithData: React.FC<{
  profile: DocumentData;
  artistGroups: DocumentData[];
  artistSignups: DocumentData[];
}> = ({ profile, artistGroups, artistSignups }) => {
  const shouldRedirect = useAuthRedirect(profile, artistGroups, artistSignups);
  const [editPassword, setEditPassword] = useState<boolean>(false);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const { user } = useUser();

  let avatarProps: AvatarProps = {
    icon: <MediaIcon />,
    bgColor: colors.gray2,
  };

  if (user.photoURL) {
    avatarProps.imgUrl = user.photoURL;
    delete avatarProps.icon;
  }

  return (
    <>
      {!shouldRedirect && (
        <PageLayout size={876}>
          <Cover />
          <Container>
            <Div alignCenter flexColumn>
              <Title size={38}>My Account</Title>
              <Avatar {...avatarProps} mb={8} />
              <Link size={12} mb={23} onClick={() => setEditProfile(true)}>
                Edit Profile
              </Link>
              <Title textAlignCenter as="h2" size={26}>
                {user.displayName}
              </Title>
              <Description textAlignCenter>{user.email}</Description>
            </Div>
            <Actions mt={48} flexColumn>
              <Button onClick={() => setEditPassword(true)}>
                Update Password
              </Button>
              <Button
                variant="secondary"
                onClick={() =>
                  window.open(
                    sessionApi.go(process.env.REACT_APP_ARTIST_PORTAL_PATH),
                    "_self"
                  )
                }
              >
                Back to SET Portal
              </Button>
            </Actions>
          </Container>
        </PageLayout>
      )}
      {editPassword && <NewPasswordFormModal setIsEditing={setEditPassword} />}
      {editProfile && (
        <ContactInfoForm setIsEditing={setEditProfile} profile={profile} />
      )}
    </>
  );
};

export default MyAccount;
