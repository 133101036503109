import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Footer } from "components/common/Footer";
import styled from "styled-components";
import { Div } from "@musicaudienceexchange/melodies/utils";
import logoSet from "images/logo-set.png";
import logoNextsong from "images/logo-nextsong.png";
import backgroundGradient from "images/background-x-overlay.png";
import { LayoutContainer } from "components/Routes/SessionRoutes/common";
import { SvgBack } from "@musicaudienceexchange/melodies/Svgs/Back";
import { usePlatform } from "hooks";
import { Button as ButtonBase } from "@musicaudienceexchange/melodies/Button";
import { useUser } from "auth";

interface PageProps {
  children?: React.ReactNode;
  onBackNavigate?: VoidFunction;
  headerHeight?: string;
  showSignout?: boolean;
}

export const Page = ({
  children,
  onBackNavigate,
  headerHeight,
  showSignout,
  ...props
}: PageProps) => {
  const { signOut } = useUser();
  const platform = usePlatform();
  const isNextsong = platform === "nextsong";
  const platformLogoPath = platform === "nextsong" ? logoNextsong : logoSet;
  return (
    <Wrapper flexColumn {...props}>
      <Toolbar dflex alignCenter>
        <LogoImage
          src={platformLogoPath}
          style={{ marginBottom: isNextsong ? "-4px" : 0 }}
        />
        {showSignout && (
          <Button variant="tertiary" onClick={() => signOut()}>
            Sign Out
          </Button>
        )}
      </Toolbar>
      <DesktopHeader
        dflex
        alignCenter
        isNextsong={isNextsong}
        background={backgroundGradient}
        height={headerHeight}
      >
        {onBackNavigate && (
          <Navigation>
            <SvgBack onClick={onBackNavigate && onBackNavigate} tabIndex={-1} />
          </Navigation>
        )}
      </DesktopHeader>
      <LayoutContainer flexColumn grow>
        <BodyContainer flexColumn grow w100 pRelative maxw={380}>
          {children}
        </BodyContainer>
        <Footer background={colors.white} />
      </LayoutContainer>
    </Wrapper>
  );
};

const Button = styled(ButtonBase)`
  width: auto;

  ${(p) => p.theme.mediaQueries.mobile} {
    font-size: 14px;
    line-height: 20px;
    height: 32px;
  }
`;

const Navigation = styled(Div)`
  margin-left: 20px;
  z-index: 3;
  svg {
    color: #ffffff;
    width: 36px;
    height: 36px;
    &:hover {
      cursor: pointer;
    }
  }
  ${(p) => p.theme.mediaQueries.desktop} {
    margin-left: 44px;
  }
`;

interface HeaderProps {
  isNextsong?: boolean;
  background?: string;
  height?: string;
}

const DesktopHeader = styled(Div)<HeaderProps>`
  background: url(${(props) => props.background}),
    ${(props) =>
      props.isNextsong
        ? "linear-gradient(45deg, #716AD0 0%, #419DA6 100%)"
        : "linear-gradient(45deg, #1b0076 0%, #e71e3d 100%)"};
  background-position: left center;
  background-size: 461px, 100%;
  background-repeat: no-repeat;
  position: relative;
  height: ${(props) => props.height || "140px"};
  margin-bottom: -${(props) => props.height || "108px"};
  ${(p) => p.theme.mediaQueries.mobile} {
    background-size: 201px, 100%;
    height: 108px;
    margin-bottom: -108px;
  }
`;

const LogoImage = styled.img`
  width: auto;
  height: 32px;
  ${(p) => p.theme.mediaQueries.mobile} {
    height: 24px;
  }
`;

const BodyContainer = styled(Div)`
  align-items: center;
  padding-bottom: 66px;
  ${(p) => p.theme.mediaQueries.mobile} {
    padding-bottom: 28px;
  }
`;

const Wrapper = styled(Div)`
  background-color: ${(props) => props.theme.colors.gray1};
  min-height: 100vh;
  position: relative;
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;

const Toolbar = styled(Div)`
  background-color: #ffffff;
  padding: 0 16px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${(p) => p.theme.mediaQueries.mobile} {
    height: 40px;
  }
`;
