import React from "react";
import { Route, Switch } from "react-router-dom";
import { AuthRedirect } from "../common";
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import LoginWithPassword from "./LoginWithPassword";
import { useUser } from "auth";

export const SESSION_ROUTES = {
  LOGIN_WITH_SSO: "/login-sso",
  FORGOT_PASSWORD: "/forgot-password",
  NEW_PASSWORD: "/new-password",
  LOGIN: "/login",
};

export const SESSION_VIEW_PATH = "/user";
const p = (path) => `${SESSION_VIEW_PATH}${path}`;

export const LOGIN_ROUTE = p(`${SESSION_ROUTES.LOGIN}`);
export const LOGIN_WITH_SSO = p(`${SESSION_ROUTES.LOGIN_WITH_SSO}`);
export const SIGN_UP_ROUTE = p(`${SESSION_ROUTES.LOGIN}`);
export const FORGOT_PASSWORD_ROUTE = p(`${SESSION_ROUTES.FORGOT_PASSWORD}`);
export const NEW_PASSWORD_ROUTE = p(`${SESSION_ROUTES.NEW_PASSWORD}`);

const SessionRoutes: React.FC = () => {
  const { isLoggedIn } = useUser();

  return (
    <Switch>
      <Route exact path={LOGIN_WITH_SSO}>
        <Login />
      </Route>
      <Route exact path={FORGOT_PASSWORD_ROUTE}>
        <ForgotPassword />
      </Route>
      <Route exact path={LOGIN_ROUTE}>
        <LoginWithPassword />
      </Route>
      <Route>
        <AuthRedirect to={isLoggedIn ? "/" : LOGIN_ROUTE} />
      </Route>
    </Switch>
  );
};

export default SessionRoutes;
