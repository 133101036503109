var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useEffect, useState } from "react";
import { getApp } from "firebase/app";
import { signOut, isSignInWithEmailLink, getAuth, onAuthStateChanged, onIdTokenChanged, updateProfile, setPersistence, inMemoryPersistence, updatePassword, signInAnonymously, } from "firebase/auth";
import { getFirestore, doc, setDoc, updateDoc } from "firebase/firestore";
import { AppLoading } from "./AppLoading";
import { usePrevious, getParams, getUrl } from "./helpers";
import * as session from "./session";
var RenewalErrorCodes = [
    "auth/expired-action-code",
    "auth/invalid-action-code",
];
export var sessionApi = session;
export var defaultLoadClaims = function (data) { return ({
    admin: typeof (data === null || data === void 0 ? void 0 : data.admin) === "boolean" && (data === null || data === void 0 ? void 0 : data.admin) === true,
}); };
var AuthStack = [];
var callCount = 0;
var addAuthState = function (state) {
    if (!AuthStack.find(function (p) { return p.user === state.user && p.prevUser === state.prevUser; })) {
        AuthStack.push(state);
        return true;
    }
    return false;
};
export function CreateAuthentication(_a) {
    var _this = this;
    var _b;
    var _c = _a === void 0 ? {} : _a, firebaseApp = _c.firebaseApp, firebaseAuthDomain = _c.firebaseAuthDomain, loadClaims = _c.loadClaims, fallbackToAnon = _c.fallbackToAnon;
    var auth = getAuth(firebaseApp);
    var firestore = firebaseApp ? getFirestore(firebaseApp) : getFirestore();
    if (!firebaseAuthDomain) {
        firebaseAuthDomain = (_b = (firebaseApp || getApp())) === null || _b === void 0 ? void 0 : _b.options.authDomain;
    }
    if (!firebaseAuthDomain) {
        console.error("Could not determine firebaseAuthDomain.  Auth will likely fail.");
    }
    // Since we're using session cookies, it is important that we do not persist
    // the firebase auth
    setPersistence(auth, inMemoryPersistence);
    var userLoadClaims = loadClaims
        ? loadClaims
        : function (data) { return defaultLoadClaims(data); };
    var UserContext = createContext({});
    var useUser = function () { return useContext(UserContext); };
    var UserProvider = function (_a) {
        var _b = _a.blockRendering, blockRendering = _b === void 0 ? true : _b, children = _a.children;
        var _c = useState(), user = _c[0], setUser = _c[1];
        var _d = useState(), claims = _d[0], setClaims = _d[1];
        var _e = useState(false), isLoaded = _e[0], setIsLoaded = _e[1];
        var _f = useState(), hasExpiredSignInWithEmailLink = _f[0], setHasExpiredSignInWithEmailLink = _f[1];
        var prevUser = usePrevious(user);
        var clearSession = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, session.removeCookie()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, signOut(auth).catch(function (error) {
                                return console.error("Error signing out", error);
                            })];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        useEffect(function () {
            var handler = function (user) { return __awaiter(_this, void 0, void 0, function () {
                var _a, search, href, params, isMagicLink, user_1;
                return __generator(this, function (_b) {
                    switch (_b.label) {
                        case 0:
                            console.debug("onAuthStateChanged handler", prevUser, user);
                            _a = window.location, search = _a.search, href = _a.href;
                            params = getParams(search);
                            isMagicLink = isSignInWithEmailLink(auth, href);
                            if (prevUser === user)
                                return [2 /*return*/];
                            if (!(prevUser === undefined)) return [3 /*break*/, 4];
                            if (!(user === null)) return [3 /*break*/, 4];
                            return [4 /*yield*/, session.resume({
                                    href: href,
                                    firebaseError: function (error) {
                                        if (RenewalErrorCodes.includes(error.code) && isMagicLink) {
                                            setHasExpiredSignInWithEmailLink(true);
                                        }
                                        else {
                                            setHasExpiredSignInWithEmailLink(false);
                                        }
                                    },
                                }, firebaseApp)];
                        case 1:
                            user_1 = _b.sent();
                            if (user_1) {
                                // Automatic sign-in successful -- bail and wait for next call
                                // to set the state
                                console.debug("Call to session.resume succeeded");
                                return [2 /*return*/];
                            }
                            if (!(fallbackToAnon === true)) return [3 /*break*/, 3];
                            console.debug("No user, logging in anonymously");
                            return [4 /*yield*/, signInAnonymously(auth)];
                        case 2: return [2 /*return*/, _b.sent()];
                        case 3:
                            // Automatic sign-in failed.  Redirect to login page?
                            console.debug("Automatic sign-in failed");
                            _b.label = 4;
                        case 4:
                            if (!(prevUser && !user)) return [3 /*break*/, 6];
                            // User signed out, destroy session cookie as well
                            return [4 /*yield*/, session.removeCookie()];
                        case 5:
                            // User signed out, destroy session cookie as well
                            _b.sent();
                            _b.label = 6;
                        case 6:
                            if (!user) return [3 /*break*/, 12];
                            if (!(isMagicLink && params.email && user.email !== params.email)) return [3 /*break*/, 8];
                            // User account mismatch
                            console.debug("User account mismatch; clearSession");
                            return [4 /*yield*/, clearSession()];
                        case 7:
                            _b.sent();
                            return [2 /*return*/];
                        case 8:
                            if (!isMagicLink) return [3 /*break*/, 10];
                            console.debug("Calling session.resume: ".concat(href));
                            return [4 /*yield*/, session.resume({ href: href }, firebaseApp)];
                        case 9:
                            _b.sent();
                            _b.label = 10;
                        case 10:
                            // Set/update the session cookie
                            console.debug("Calling session.init", user);
                            return [4 /*yield*/, session.init(user)];
                        case 11:
                            _b.sent();
                            _b.label = 12;
                        case 12:
                            if (user &&
                                !(user === null || user === void 0 ? void 0 : user.email) &&
                                !user.isAnonymous &&
                                user.providerData.length === 0) {
                                // This looks pretty hacky, so here's why we do this.  The above call
                                // to `session.resume` hits a backend function that checks the user's
                                // session cookie and if valid returns a sign-in token.  The act of
                                // generating this token will "upgrade" an anonymous account to a
                                // "custom" account, however this "custom" account does not look
                                // any different than an anonymous account.  So, if the account here
                                // looks like one of these "custom" account, we force it into looking
                                // like an anonymous account.
                                console.debug("Forcing isAnonymous to true");
                                Object.defineProperty(user, "isAnonymous", { value: true });
                            }
                            // Finally set the user state
                            console.debug("Setting user:", user);
                            setUser(user);
                            setIsLoaded(true);
                            return [2 /*return*/];
                    }
                });
            }); };
            var unsub = onAuthStateChanged(auth, function (user) { return __awaiter(_this, void 0, void 0, function () {
                return __generator(this, function (_a) {
                    switch (_a.label) {
                        case 0:
                            console.debug("onAuthStateChanged ".concat(++callCount), prevUser, user, JSON.stringify(AuthStack));
                            if (!addAuthState({ user: user, prevUser: prevUser })) return [3 /*break*/, 2];
                            return [4 /*yield*/, handler(user).finally(function () {
                                    console.debug("Setting isRunning to false");
                                })];
                        case 1:
                            _a.sent();
                            _a.label = 2;
                        case 2: return [2 /*return*/];
                    }
                });
            }); });
            return function () {
                console.debug("onAuthStateChanged: unsub");
                unsub();
            };
        }, [prevUser]);
        useEffect(function () {
            return onIdTokenChanged(auth, function (user) {
                return user === null || user === void 0 ? void 0 : user.getIdTokenResult().then(function (res) { return setClaims(userLoadClaims(res.claims)); });
            });
        }, []);
        var updateName = function (firstName, lastName) { return __awaiter(_this, void 0, void 0, function () {
            var displayName, userProfile;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        displayName = "".concat(firstName, " ").concat(lastName);
                        userProfile = {
                            fullName: displayName,
                            name: {
                                firstName: firstName,
                                lastName: lastName,
                            },
                            email: user === null || user === void 0 ? void 0 : user.email,
                        };
                        if (!user) return [3 /*break*/, 3];
                        return [4 /*yield*/, setDoc(doc(firestore, "profiles/".concat(user === null || user === void 0 ? void 0 : user.uid)), userProfile, {
                                merge: true,
                            })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, updateProfile(user, { displayName: displayName })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var doUpdatePassword = function (password) { return __awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user) return [3 /*break*/, 2];
                        return [4 /*yield*/, updatePassword(user, password)
                                .then(function () { return __awaiter(_this, void 0, void 0, function () {
                                var token;
                                return __generator(this, function (_a) {
                                    switch (_a.label) {
                                        case 0: return [4 /*yield*/, user.getIdToken(true)];
                                        case 1:
                                            token = _a.sent();
                                            return [4 /*yield*/, sessionApi.removeCookie()];
                                        case 2:
                                            _a.sent();
                                            return [4 /*yield*/, sessionApi.init(token)];
                                        case 3:
                                            _a.sent();
                                            return [2 /*return*/, true];
                                    }
                                });
                            }); })
                                .catch(function (error) {
                                console.error("Error updating password", error);
                                return false;
                            })];
                    case 1: return [2 /*return*/, _a.sent()];
                    case 2: return [2 /*return*/, false];
                }
            });
        }); };
        var updatePhotoURL = function (photoURL) { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!user) return [3 /*break*/, 3];
                        return [4 /*yield*/, updateDoc(doc(firestore, "profiles/".concat(user === null || user === void 0 ? void 0 : user.uid)), { photoURL: photoURL })];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, updateProfile(user, { displayName: user.displayName, photoURL: photoURL })];
                    case 2:
                        _a.sent();
                        _a.label = 3;
                    case 3: return [2 /*return*/];
                }
            });
        }); };
        var redirectToLogin = function (redirect) {
            var path = typeof redirect === "string" ? redirect : window.location.pathname;
            var loginUrl = getUrl({
                domain: firebaseAuthDomain,
                params: { redirect: getUrl({ path: path }) },
            });
            window.location.href = loginUrl;
        };
        var doSignOut = function (redirect) { return __awaiter(_this, void 0, void 0, function () {
            var path, signOutUrl;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        path = typeof redirect === "string" ? redirect : "/";
                        return [4 /*yield*/, clearSession()];
                    case 1:
                        _a.sent();
                        if (firebaseAuthDomain !== window.location.origin && redirect !== false) {
                            signOutUrl = getUrl({
                                domain: firebaseAuthDomain,
                                params: {
                                    signOut: "true",
                                    redirect: getUrl({ path: path }),
                                },
                            });
                            window.location.href = signOutUrl;
                        }
                        return [2 /*return*/];
                }
            });
        }); };
        return (_jsx(UserContext.Provider, __assign({ value: {
                user: user,
                claims: claims,
                isLoggedIn: !!user,
                redirectToLogin: redirectToLogin,
                updateName: updateName,
                updatePhotoURL: updatePhotoURL,
                signOut: doSignOut,
                clearSession: clearSession,
                updatePassword: doUpdatePassword,
                hasExpiredSignInWithEmailLink: hasExpiredSignInWithEmailLink,
            } }, { children: blockRendering && !isLoaded ? _jsx(AppLoading, {}) : children })));
    };
    return {
        UserContext: UserContext,
        UserProvider: UserProvider,
        useUser: useUser,
    };
}
