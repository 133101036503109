import React, { useEffect } from "react";
import { doc, DocumentData } from "firebase/firestore";
import { AppLoading } from "components/AppLoading";
import { useDocumentData } from "react-firebase-hooks/firestore";
import { useUser } from "auth";
import { useFirebase } from "providers/FirebaseProvider";
import { ROUTES } from "..";
import { Redirect, Route, Switch } from "react-router";
import { UpdateName } from "./UpdateName";
import { UpdatePassword } from "./UpdatePassword";
import { useHistoryPush } from "components/common";

export const AccountSetup: React.FC = () => {
  const { firestore } = useFirebase();
  const navigate = useHistoryPush();
  const { user } = useUser();
  const [profile, isLoadingProfile] = useDocumentData(
    doc(firestore, `profiles/${user?.uid}`)
  );

  useEffect(() => {
    if (
      !isLoadingProfile &&
      profile &&
      !!profile?.name?.firstName &&
      !!profile?.name?.lastName &&
      !profile?.requiresOnboarding
    ) {
      navigate(ROUTES.MY_ACCOUNT);
    }
  }, [profile]);

  return (
    <>
      {isLoadingProfile ? <AppLoading /> : <AccountRoutes profile={profile} />}
    </>
  );
};

const AccountRoutes: React.FC<{ profile: DocumentData }> = ({ profile }) => {
  return (
    <Switch>
      <Route exact path={ROUTES.accountSetup.name}>
        <UpdateName profile={profile} />
      </Route>
      <Route exact path={ROUTES.accountSetup.password}>
        <UpdatePassword profile={profile} />
      </Route>
      <Route>
        <Redirect to={ROUTES.accountSetup.name} />
      </Route>
    </Switch>
  );
};
