import { useEffect, useState } from "react";
import styled from "styled-components";
import { useIsMobile } from "../../../../../hooks";
import {
  Modal as ModalBase,
  ModalContainer,
  ModalWrapper,
} from "@musicaudienceexchange/melodies/Modal";
import ImageCrop from "./ImageCrop";
import ImageDropzone from "./ImageDropzone";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Button } from "@musicaudienceexchange/melodies/Button";
import { Body1, H2 } from "@musicaudienceexchange/melodies/Text";

const imageStylesDesktop = {
  height: "300px",
};

const imageStylesMobile = {
  height: "250px",
};

const ImageWrapper = styled(Div)`
  width: 100%;
  overflow: hidden;
  border: 1px solid ${(props) => props.theme.colors.gray3};
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${imageStylesDesktop};
  ${(props) => props.theme.mediaQueries.mobile} {
    ${imageStylesMobile}
  }
`;

const Modal = styled(ModalBase)`
  ${ModalContainer} {
    ${(p) => p.theme.mediaQueries.desktop} {
      margin-top: 0;
      justify-content: center;
    }
    ${(p) => p.theme.mediaQueries.mobile} {
      padding-left: 24px;
      padding-right: 24px;
    }
  }
  ${ModalWrapper} {
    width: 100%;
    ${(p) => p.theme.mediaQueries.desktop} {
      margin-bottom: 0;
      width: 480px;
    }
    ${(p) => p.theme.mediaQueries.mobile} {
      ${H2} {
        font-size: 20px;
        line-height: 28px;
      }
    }
  }
`;

const DescriptionText = styled(Div)`
  ${(p) => p.theme.mediaQueries.mobile} {
    font-size: 17px;
  }
`;

const SelectImageModal = ({ isOpen, onClose, onSelect = null, ...props }) => {
  useEffect(() => {
    if (isOpen) document.body.style.overflow = "hidden";
    else document.body.style.overflow = "auto";
  }, [isOpen]);

  return (
    <Modal
      header={`Add ${props.label}`}
      isOpen={isOpen}
      withCloseIcon={false}
      onClose={onClose}
      withBackdropClose={false}
    >
      <ModalContent
        {...{
          onClose,
          onSelect,
          ...props,
        }}
      />
    </Modal>
  );
};

const ButtonsContainer = styled(Div)`
  width: 300px;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 180px;
  }
`;

const ModalContent = ({ onClose, onSelect, ...props }) => {
  const [image, setImage] = useState(null);
  const [croppedImg, setCroppedImg] = useState(null);
  const { aspectRatio, description, dropzoneDescription } = props;

  const headerUI = (
    <Div c={colors.black80}>
      <DescriptionText fs={17} mt={5} lite>
        {description}
      </DescriptionText>
    </Div>
  );

  const saveButtonUI = (
    <Div
      w100
      onClick={() => {
        if (croppedImg) {
          onSelect(croppedImg);
          onClose();
        }
      }}
    >
      <Button isDisabled={!croppedImg}>Save</Button>
    </Div>
  );

  const cancelButtonUI = (
    <Div onClick={onClose} w100>
      <Button variant="tertiary">Cancel</Button>
    </Div>
  );

  const isMobile = useIsMobile();
  const imageStyles = isMobile ? imageStylesMobile : imageStylesDesktop;
  return (
    <>
      {description && headerUI}
      <Div mb={5}>
        <Body1 mb={5} mt={20}>
          {image ? "Preview" : "Image Upload"}
        </Body1>
        {!image ? (
          <ImageDropzone
            onSelect={(img) => setImage(img)}
            Wrapper={ImageWrapper}
            placeholderText={dropzoneDescription}
          />
        ) : (
          <ImageCrop
            srcImg={image}
            options={{ aspect: aspectRatio }}
            onCrop={(img) => setCroppedImg(img)}
            Wrapper={ImageWrapper}
            style={imageStyles}
          />
        )}
        <Div justifyEnd w100>
          <ButtonsContainer flexRowReverse mt={30}>
            {saveButtonUI}
            <Div ml={8} mr={8} />
            {cancelButtonUI}
          </ButtonsContainer>
        </Div>
      </Div>
    </>
  );
};

export default SelectImageModal;
