import { SvgKey } from "@musicaudienceexchange/melodies/Svgs/Key";
import { SvgMail } from "@musicaudienceexchange/melodies/Svgs/Mail";
import {
  ErrorMessageWarningContainer,
  Form,
  FormikSumbitButton,
  FormikTextInput,
  yupEmail,
  yupRequired,
} from "@musicaudienceexchange/melodies/forms";
import { Div, HtmlP } from "@musicaudienceexchange/melodies/utils";
import { useAppContext } from "AppContextProvider";
import { Divider, useHistoryPush } from "components/common";

import {
  Description,
  Link,
  PageLayout,
  SetLogo,
} from "components/common/PageLayout";
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import React, { useState } from "react";
import styled from "styled-components";
import { FORGOT_PASSWORD_ROUTE, LOGIN_WITH_SSO } from ".";

const Container = styled(Div)`
  padding: 40px 48px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;

const Header = styled(Div)`
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 32px;
  }
`;

const Body = styled(Div)`
  gap: 24px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    gap: 22px;
  }
`;

const Actions = styled(Div)`
  gap: 20px;
`;

const Text = styled(HtmlP)`
  font-size: 16px;
  color: #333333;
`;

const fields = [
  {
    name: "email",
    label: "Email",
    validation: yupEmail,
    initialValue: "",
  },
  {
    name: "password",
    label: "Password",
    validation: yupRequired,
    initialValue: "",
  },
];

const emailLogin = async (email: string, password: string) => {
  const auth = getAuth();
  return signInWithEmailAndPassword(auth, email, password);
};

const LoginWithPassword: React.FC = () => {
  const { setAppIsLoading } = useAppContext();
  const [isInvalidCredential, setIsInvalidCredential] = useState(false);
  const historyPush = useHistoryPush();

  const onSubmitForm = async (values: { email: string; password: string }) => {
    const { email, password } = values;
    try {
      setAppIsLoading(true);
      await emailLogin(email, password);
    } catch (err) {
      setIsInvalidCredential(true);
    }
    setAppIsLoading(false);
  };

  return (
    <PageLayout size={476} light={false} header={false}>
      <Container>
        <Header flexColumn alignCenter>
          <SetLogo />
          <Description textAlignCenter>
            Log in to access your artist account
          </Description>
        </Header>
        <Form fields={fields} onSubmit={onSubmitForm} w100>
          <Body flexColumn>
            {isInvalidCredential && (
              <ErrorMessageWarningContainer>
                You entered an incorrect email, password, or both.
              </ErrorMessageWarningContainer>
            )}
            <FormikTextInput
              label="Email Address"
              leftIcon={<SvgMail />}
              name="email"
              placeholder="Email..."
            />
            <Div>
              <FormikTextInput
                label="Password"
                leftIcon={<SvgKey />}
                type="password"
                name="password"
                placeholder="Password..."
              />
              <Div justifyEnd pt={7}>
                <Link
                  onClick={() => historyPush(FORGOT_PASSWORD_ROUTE)}
                  size={14}
                >
                  Forgot Password?
                </Link>
              </Div>
            </Div>
            <FormikSumbitButton type="submit" tabIndex={0}>
              Log In
            </FormikSumbitButton>
          </Body>
        </Form>
        <Actions mt={24} flexColumn>
          <Link textAlignCenter onClick={() => historyPush(LOGIN_WITH_SSO)}>
            Use a One-Time Login Link
          </Link>
          <Divider />
          <Text textAlignCenter>
            Don’t have an account?{" "}
            <Link as="a" href={process.env.REACT_APP_SIGNUP_PATH}>
              Get Started
            </Link>
          </Text>
        </Actions>
      </Container>
    </PageLayout>
  );
};

export default LoginWithPassword;
