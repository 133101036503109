import React from "react";
import { Caption as CaptionBase } from "@musicaudienceexchange/melodies/Text";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { usePlatform } from "hooks";

import { useUser } from "auth";

import styled, { css } from "styled-components";
import { AppContainer } from "./layout";
import { LINKS } from "./links";

const Caption = styled(CaptionBase)(
  ({ theme: { colors, css: c } }) => css`
    color: black;
  `
);

type ContainerProps = {
  background: string;
};

const Container = styled(Div)<ContainerProps>`
  color: ${(p: any) => p.color};
  background: ${(p: any) => p.background};
  padding-bottom: 35px;

  svg {
    path {
      fill: ${(p) => p.color};
    }
  }
  ${(p) => p.theme.mediaQueries.mobile} {
    padding-bottom: 4%;
  }
`;

const FooterLink = styled(Div)`
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => props.color};
  :hover,
  :focus {
    color: ${(props) => props.color};
  }
  margin-right: 12px;
  margin-left: 12px;
  ${(props) => props.theme.mediaQueries.mobile} {
    margin: 0;
    text-align: center;
  }
`;

type WrapperProps = {
  withLogo: boolean;
};
const Wrapper = styled(Div)<WrapperProps>`
  width: 100%;
  ${(p) =>
    p.withLogo
      ? css`
          display: flex;
          justify-content: space-between;
        `
      : css`
          display: grid;
          place-items: center;
        `}
  ${(p) => p.theme.mediaQueries.mobile} {
    display: block;
  }
`;

const LinksWrapper = styled(Div)`
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 8px;
    column-gap: 24px;
  }
`;

/*
const links = [
  {
    label: "About SET",
    url: "https://about.set.live",
  },
  {
    label: "Privacy Policy",
    url: "https://set.live/privacy",
  },
  {
    label: "Terms of Use",
    url: "https://set.live/terms",
  },
  {
    label: "Contact Us",
    url: "https://www.max.live/contact",
  },
  //`{
  //`  label: "FAQ",
  //`  path: "/faq",
  //`},
];
*/

type Props = {
  withLogo?: boolean;
  color?: string;
  background?: string;
  className?: string;
};

export const Footer = ({
  withLogo = true,
  color = colors.black,
  background = colors.black40,
  className,
}: Props) => {
  const { claims } = useUser();
  const { isAdmin } = claims || { isAdmin: false };

  const platform = usePlatform();

  const copyrightUI = (
    <Caption>
      © {new Date().getFullYear()}{" "}
      {platform === "nextsong" ? "NextSong.Live" : "Set.Live"}
    </Caption>
  );

  const links = LINKS[platform];

  return (
    <Container
      color={color}
      background={"transparent"}
      mcontainer
      className={className}
    >
      <AppContainer>
        <Wrapper withLogo={withLogo}>
          <LinksWrapper flexWrap>
            {links.map((link, index) => (
              <FooterLink
                color={color}
                clickable
                key={index}
                onClick={() => {
                  //if (link.path) gotoPage(link.path);
                  if (link.url) window.open(link.url);
                }}
              >
                {link.label}
              </FooterLink>
            ))}
          </LinksWrapper>
        </Wrapper>
        <Div mt={20} centered>
          {copyrightUI}
        </Div>
        {isAdmin &&
          (process.env.REACT_APP_GIT_SHA ||
            process.env.REACT_APP_RELEASE_VERSION) && (
            <div
              style={{
                textAlign: "center",
                fontSize: "10px",
                paddingBottom: "10px",
                color: "#999",
              }}
            >
              <span style={{ paddingLeft: "10px" }}>
                {[
                  process.env.REACT_APP_RELEASE_VERSION,
                  process.env.REACT_APP_GIT_SHA &&
                    `#${process.env.REACT_APP_GIT_SHA}`,
                ]
                  .filter((v) => !!v)
                  .join(" | ")}
              </span>
            </div>
          )}
      </AppContainer>
    </Container>
  );
};
