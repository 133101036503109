import { BrowserRouter as Router } from "react-router-dom";
import { Toast } from "@musicaudienceexchange/melodies/Toast";
import { AppContextProvider } from "AppContextProvider";
import { FirebaseProvider } from "providers/FirebaseProvider";
import { UserProvider } from "auth";
import Routes from "components/Routes";
import { LogrocketHelper } from "LogrocketHelper";
import "./App.css";
import { AuthHandler } from "hooks/AuthHandler";
import Theme from "Theme";

function App() {
  return (
    <FirebaseProvider>
      <Router>
        <Theme>
          <AppContextProvider>
            <UserProvider>
              <LogrocketHelper />
              <Toast />
              <AuthHandler>
                <Routes />
              </AuthHandler>
            </UserProvider>
          </AppContextProvider>
        </Theme>
      </Router>
    </FirebaseProvider>
  );
}

export default App;
