import { Subtitle1 } from "@musicaudienceexchange/melodies/Text";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useQueryParams } from "hooks";
import { useHistory, useLocation } from "react-router";
import styled from "styled-components";
import { getUrl } from "utils";
import * as yup from "yup";

export const yupStrictPassword = yup
  .string()
  .required()
  .min(8, "Must contain at least 8 characters.")
  .matches(/^(?=.*[A-Z])/, "Must contain at least one uppercase character.")
  .matches(/^(?=.*[a-z])/, "Must contain at least one lowercase character.")
  .matches(
    /^(?=.*[0-9])|(?=.*[!@#\$%\^&\*])/,
    "Must contain at least one number or special character, (e.g., ! @ # $ % ^ & *)"
  );

// used to preserve redirect query param in url
interface RouteState {
  [key: string | number]: any;
}

export const useHistoryPush = () => {
  const location = useLocation();
  const history = useHistory();
  return (route: string, state?: RouteState, isReplace?: boolean) => {
    const routeOptions = {
      pathname: route,
      search: location.search,
      state: state,
    };
    !isReplace ? history.push(routeOptions) : history.replace(routeOptions);
  };
};

const queryParams = ["redirect", "signOut", "resetPassword"] as const;
export type QueryParam = typeof queryParams[number];

export type AuthUrlParams = { [k in QueryParam]?: string };
export type GetAuthUrlParams = {
  domain?: string;
  path?: string;
  params?: AuthUrlParams;
};
export const getAuthUrl = (params: GetAuthUrlParams) => getUrl(params);

export const useAuthQueryParams = (queryParams: QueryParam[]): AuthUrlParams =>
  useQueryParams(queryParams as any);

export const Divider = styled(Div)`
  height: 1px;
  width: 100%;
  background: ${(p) => p.theme.colors.gray4};
`;

export const DividerWithLabel = ({
  children,
  ...props
}: {
  children?: React.ReactNode;
}) => {
  return (
    <Container alignCenter justifyCenter {...props}>
      {children && (
        <LabelContainer>
          <Subtitle1>{children}</Subtitle1>
        </LabelContainer>
      )}
      <Divider />
    </Container>
  );
};

const Container = styled(Div)`
  height: 10px;
  width: 100%;
  margin: 28px 0;
`;

const LabelContainer = styled(Div)`
  padding: 0 12px;
  background-color: ${(props) => props.theme.colors.gray1};
  position: absolute;
  ${Subtitle1} {
    color: ${(props) => props.theme.colors.black80};
  }
`;
