import { Button } from "@musicaudienceexchange/melodies/Button";
import { Link as LinkBase } from "@musicaudienceexchange/melodies/Link";
import { Div, HtmlH1, HtmlP } from "@musicaudienceexchange/melodies/utils";
import { useUser } from "auth";
import backgroundLightImage from "images/background-light.jpg";
import backgroundDarkImage from "images/background.jpg";
import setLogoImage from "images/logo-set-2x.png";
import { ComponentType } from "react";
import styled, { css } from "styled-components";
import { LINKS } from "./links";

const darkBackground = css`
  background: linear-gradient(330deg, #1b0076 5%, transparent 100%),
    url(${backgroundDarkImage}) center / cover no-repeat, #190235;
  background-blend-mode: darken, normal, normal;
`;

const lightBackground = css`
  background: linear-gradient(180deg, transparent 50%, white 100%),
    linear-gradient(130deg, #e71e3d40 0%, transparent 40%),
    url(${backgroundLightImage}) center / cover no-repeat, lightgray;
`;

const Wrapper = styled(Div)<{ light?: boolean }>`
  min-height: 100vh;
  ${({ light }) => (light ? lightBackground : darkBackground)};

  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
`;

const Header = styled(Div)`
  background: #fff;
  box-shadow: 0px -1px 0px 0px #e6e9eb inset;
  height: 68px;
  padding: 0 20px;
`;

const HeaderLogo = styled.img.attrs(() => ({
  alt: "Set.Live",
  src: setLogoImage,
}))`
  width: 72px;
`;

const Card = styled(Div)`
  border-radius: 20px;
  border: 1px solid #cdcece;
  background: #fff;
  box-shadow: 0px 0px 50px 0px rgba(0, 0, 0, 0.15);
  margin: 0 auto;
`;

const Footer = styled(Div)<{ light?: boolean }>`
  color: ${({ light, theme }) =>
    light ? theme.colors.black : theme.colors.white};

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-direction: column-reverse;
  }
`;

const Divider = styled(Div)`
  display: none;
  height: 1px;
  width: 100%;
  background: currentcolor;
  opacity: 0.3;

  ${({ theme }) => theme.mediaQueries.mobile} {
    display: block;
  }
`;

const Copyright = styled(Div)`
  font-size: 10px;
  color: currentcolor;

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: 12px;
  }
`;

const FooterLinkList = styled(Div)`
  gap: 42px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-evenly;
  }
`;

const FooterLink = styled.a`
  font-size: 12px;
  text-decoration: none;
  flex-shrink: 0;
  color: currentcolor;
`;

export const SetLogo = styled.img.attrs(() => ({
  alt: "Set.Live",
  src: setLogoImage,
}))`
  width: 130px;
  margin-bottom: 12px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    width: 74px;
  }
`;

export const Title = styled(HtmlH1)<{ size?: number }>`
  font-size: ${({ size }) => size ?? 38}px;
  font-weight: 600;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black};

  ${({ theme }) => theme.mediaQueries.mobile} {
    font-size: ${({ size }) => (size ?? 38) * 0.7}px;
  }
`;

export const Description = styled(HtmlP)<{ size?: number }>`
  font-size: ${({ size }) => size ?? 16}px;
  font-weight: 500;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.black80};
`;

export const Link = styled(LinkBase)<{ size?: number }>`
  font-size: ${({ size }) => size ?? 16}px;
  font-weight: 600;
  color: #1b0076;
  text-decoration: none;
`;

interface PageLayoutProps {
  light?: boolean;
  header?: boolean;
  size?: number;
}

export const PageLayout: ComponentType<PageLayoutProps> = ({
  light = true,
  header = true,
  size = 400,
  children,
}) => {
  const { signOut } = useUser();

  return (
    <Wrapper light={light} flexColumn={true}>
      {header && (
        <Header spaceBetween alignCenter>
          <HeaderLogo />
          <div>
            <Button variant="tertiary" onClick={() => signOut()}>
              Sign Out
            </Button>
          </div>
        </Header>
      )}
      <Div flexColumn={true} justifyCenter={true} grow={true}>
        <Div p={20}>
          <Card style={{ maxWidth: size }}>{children}</Card>
        </Div>
      </Div>
      <Footer light={light} dflex alignCenter spaceBetween m={0} p="25px 42px">
        <Copyright>© {new Date().getFullYear()} Set.Live</Copyright>
        <Divider m="20px 0" />
        <FooterLinkList dflex>
          {LINKS.setlive.map((link) => (
            <FooterLink
              key={link.url}
              href={link.url}
              title={link.label}
              target="_blank"
              rel="noreferrer"
            >
              {link.label}
            </FooterLink>
          ))}
        </FooterLinkList>
      </Footer>
    </Wrapper>
  );
};
