import { Button } from "@musicaudienceexchange/melodies/Button";
import { SvgMail } from "@musicaudienceexchange/melodies/Svgs/Mail";
import {
  Form,
  FormikTextInput,
  yupEmail,
} from "@musicaudienceexchange/melodies/forms";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useHistoryPush } from "components/common";
import { Description, PageLayout, Title } from "components/common/PageLayout";
import { useFormikContext } from "formik";
import React, { useEffect } from "react";
import styled from "styled-components";
import { LOGIN_ROUTE } from ".";
import { useLogin } from "../common/useLogin";

const Container = styled(Div)`
  padding: 40px 48px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    padding: 32px 20px;
  }
`;

const Header = styled(Div)`
  margin-bottom: 40px;

  ${({ theme }) => theme.mediaQueries.mobile} {
    margin-bottom: 32px;
  }
`;

const emailField = {
  label: "Email",
  name: "email",
  validation: yupEmail,
  initialValue: "",
};

const ForgotPassword: React.FC = () => {
  const {
    isSubmitButtonDisabled,
    setIsSubmitButtonDisabled,
    onSubmitForm: onEmailLogin,
    emailSentUI,
  } = useLogin(true);

  return (
    <PageLayout size={476} light={false} header={false}>
      <Container>
        <Header flexColumn alignCenter>
          <Title textAlignCenter mb={12}>
            Forgot Password
          </Title>
          <Description textAlignCenter>
            Enter the email address associated with your SET account and we'll
            email you instructions to reset your password.
          </Description>
        </Header>
        <Form fields={[emailField]} onSubmit={onEmailLogin} w100>
          <FormikTextInput
            label="Email Address"
            withDebounce={false}
            leftIcon={<SvgMail />}
            name="email"
            placeholder="Email..."
          />
          {emailSentUI}
          <SubmitButton
            isButtonDisabled={isSubmitButtonDisabled}
            setIsButtonDisabled={setIsSubmitButtonDisabled}
          />
        </Form>
      </Container>
    </PageLayout>
  );
};

const SubmitButton = ({ isButtonDisabled, setIsButtonDisabled }) => {
  const { values, submitForm } = useFormikContext<any>();
  const { email } = values;
  const historyPush = useHistoryPush();

  const goToLogin = () => {
    historyPush(LOGIN_ROUTE);
  };

  useEffect(() => {
    setIsButtonDisabled(false);
  }, [email]);

  return (
    <Div spaceBetween mt={30} mb={10}>
      <Button mr={8} variant="secondary" onClick={goToLogin}>
        Back
      </Button>
      <Button
        ml={8}
        isDisabled={isButtonDisabled}
        variant="primary"
        onClick={submitForm}
      >
        Reset Password
      </Button>
    </Div>
  );
};

export default ForgotPassword;
