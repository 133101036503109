import {
  theme as baseTheme,
  ThemeType,
} from "@musicaudienceexchange/melodies/Theme";
import { HelmetProvider, Helmet } from "react-helmet-async";
import { createGlobalStyle, ThemeProvider } from "styled-components";

const mediaQueryBreakpoints = {
  desktop: "(min-width: 768px)",
  mobile: "(max-width: 767px)",
};

const mediaQueries = {
  ...baseTheme.mediaQueries,
  desktop: `@media all and ${mediaQueryBreakpoints.desktop}`,
  mobile: `@media all and ${mediaQueryBreakpoints.mobile}`,
};

const theme: ThemeType = {
  ...baseTheme,
  mediaQueries,
};

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Poppins;
  }
`;

const Theme: React.FC = ({ children, ...props }) => (
  <HelmetProvider>
    <ThemeProvider theme={theme} {...props}>
      <GlobalStyle />
      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link
          rel="preconnect"
          href="https://fonts.gstatic.com"
          crossOrigin=""
        />
        <link
          href="https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700&display=swap"
          rel="stylesheet"
        />
      </Helmet>
      {children}
    </ThemeProvider>
  </HelmetProvider>
);

export default Theme;
