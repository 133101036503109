export const LINKS = {
  nextsong: [
    {
      label: "About NextSong",
      url: "https://nextsong.live/artists",
    },
    {
      label: "Privacy Policy",
      url: "https://nextsong.live/privacy",
    },
    {
      label: "Terms of Use",
      url: "https://nextsong.live/terms",
    },
    {
      label: "Contact Us",
      url: "https://www.max.live/contact",
    },
  ],
  setlive: [
    {
      label: "About SET",
      url: "https://about.set.live",
    },
    {
      label: "Privacy Policy",
      url: "https://set.live/privacy",
    },
    {
      label: "Terms of Use",
      url: "https://set.live/terms",
    },
    {
      label: "Contact Us",
      url: "https://www.max.live/contact",
    },
  ],
};
