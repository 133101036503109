export const getBlobFromUrl = async (url) => await (await fetch(url)).blob();

export const getDisplayPrice = (cents) => `$${(cents / 100).toFixed(2)}`;

export const capitalize = (words) =>
  words.replace(/(^\w{1})|(\s+\w{1})/g, (l) => l.toUpperCase());

export const getObjectByString = (o, s) => {
  s = s.replace(/\[(\w+)\]/g, ".$1"); // convert indexes to properties
  s = s.replace(/^\./, ""); // strip a leading dot
  const a = s.split(".");
  for (let i = 0, n = a.length; i < n; ++i) {
    const k = a[i];
    if (k in o) {
      o = o[k];
    } else {
      return;
    }
  }
  return o;
};

export type NonFunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

export type NonFunctionProperties<T> = Pick<T, NonFunctionPropertyNames<T>>;

type QueryParams = { [key: string]: string };
export type GetUrlParams = {
  domain?: string;
  path?: string;
  params?: QueryParams;
};
export const getUrl = ({
  domain = window.location.origin,
  path = "/",
  params = {},
}: GetUrlParams) => {
  const url = new URL(
    path,
    domain?.match(/^https?:\/\//i) ? domain : `https://${domain}`
  );
  Object.entries(params).forEach(([key, value]) => {
    url.searchParams.set(key, value);
  });
  return url.toString();
};
