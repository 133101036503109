import { Div } from "@musicaudienceexchange/melodies/utils";
import React from "react";
import styled, { css } from "styled-components";
import ImageInput from "./ImageInput";

type ImageInputContainerProps = {
  isFirst: boolean;
};
const ImageInputContainer = styled(Div)<ImageInputContainerProps>`
  display: flex;
  flex-direction: column;
  ${(p) =>
    !p.isFirst &&
    css`
      margin-left: 20px;
    `}
  ${(p) => p.theme.mediaQueries.mobile} {
    ${(p) =>
      !p.isFirst &&
      css`
        margin-left: 0;
      `}
  }
`;

type ImgContainerProps = {
  isMobileImage: boolean;
};
const ImgContainer = styled(Div)<ImgContainerProps>`
  position: relative;
  width: 145px;
  height: 145px;
  ${(p) => p.theme.mediaQueries.mobile} {
    width: 100px;
    height: 100px;
  }
`;

const ImageInputsWrapper = styled(Div)`
  display: flex;
  justify-content: center;
`;

type Image = {
  blob: any | null;
  isInitialValue: boolean;
};

type Values = { [k: string]: Image };

type Props = {
  fields: {
    name: string;
    label: string;
    aspectRatio: number;
    description?: string;
    dropzoneDescription: string;
    width: string;
  }[];
  values: Values;
  setValues: (images: Values) => void;
};

export const SelectImageFields: React.FC<Props> = ({
  values,
  setValues,
  fields,
}) => {
  return (
    <ImageInputsWrapper>
      {fields.map((input, index) => {
        const { name, width } = input;
        const isFirst = index === 0;
        return (
          <ImageInputContainer key={index} {...{ width, isFirst }}>
            <ImgContainer isMobileImage={name === "mobileImg"}>
              <ImageInput
                {...input}
                value={values[name].blob}
                onSelect={(blob) =>
                  setValues({
                    ...values,
                    [name]: { blob, isInitialValue: false },
                  })
                }
              />
            </ImgContainer>
          </ImageInputContainer>
        );
      })}
    </ImageInputsWrapper>
  );
};
