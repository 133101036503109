import styled from "styled-components";
import {
  Form,
  FormikNameInput,
  FormikSumbitButton,
} from "@musicaudienceexchange/melodies/forms";
import { Label } from "@musicaudienceexchange/melodies/Text";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useAppContext } from "AppContextProvider";
import { Page } from "components/common/Page";
import { useUser } from "auth";
import { DocumentData } from "firebase/firestore";
import { ROUTES } from "..";
import { useHistoryPush } from "components/common";
import * as yup from "yup";
import { PageDescription, PageTitle } from "components/common/layout";
import { PageIcon } from "components/common/PageIcon";
import { usePlatform } from "hooks";

export const UpdateName = ({ profile }: { profile?: DocumentData }) => {
  const { user } = useUser();
  const { setAppIsLoading, userName, setUserName } = useAppContext();
  const navigate = useHistoryPush();
  const platform = usePlatform();
  const isNextsong = platform === "nextsong";

  const name = userName || profile?.name || {};

  const fields = [
    {
      label: "First Name",
      name: "firstName",
      validation: yup.string().trim().required("This is a required field"),
      initialValue: name?.firstName || "",
    },
    {
      label: "Last Name",
      name: "lastName",
      validation: yup.string().trim().required("This is a required field"),
      initialValue: name?.lastName || "",
    },
  ];

  const onSubmitForm = async (values: {
    firstName: string;
    lastName: string;
  }) => {
    const { firstName, lastName } = values;
    setAppIsLoading(true);
    setUserName({ firstName, lastName });
    setAppIsLoading(false);
    navigate(ROUTES.accountSetup.password, undefined, true);
  };

  return (
    <Page showSignout>
      <HeaderIcon dflex justifyCenter mt={24}>
        <PageIcon name="welcome" />
      </HeaderIcon>
      <Header mt={20} mb={40} flexColumn alignCenter>
        <PageTitle>Welcome To {isNextsong ? "NextSong" : "Set.Live"}</PageTitle>
        <PageDescription>{user?.email}</PageDescription>
      </Header>
      <FormContainer flexColumn grow>
        <Form fields={fields} onSubmit={onSubmitForm}>
          <Div mb={5}>
            <Label>Please enter your name</Label>
          </Div>
          <RemoveLabel>
            <FormikNameInput />
          </RemoveLabel>
          <Button disableOnErrors tabIndex={0}>
            Continue
          </Button>
        </Form>
      </FormContainer>
    </Page>
  );
};

const HeaderIcon = styled(Div)`
  margin-top: 24px;
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 16px;
  }
`;

const Header = styled(Div)`
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 0;
    margin-bottom: 24px;
  }
`;

const Button = styled(FormikSumbitButton)`
  margin-top: 16px;
  &:focus {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }
`;

const FormContainer = styled(Div)`
  padding-bottom: 66px;
  ${(p) => p.theme.mediaQueries.mobile} {
    padding-bottom: 28px;
  }
`;

const RemoveLabel = styled(Div)`
  label {
    display: none;
  }
`;
