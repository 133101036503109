import { H3 } from "@musicaudienceexchange/melodies/Text";
import { Div } from "@musicaudienceexchange/melodies/utils";
import styled from "styled-components";

export const AppLayout = styled(Div)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100vh;
`;

export const Container = styled(Div)`
  ${(p) => p.theme.css.appPaddingLeft};
  ${(p) => p.theme.css.appPaddingRight};
  width: 100%;
  max-width: 1224px;
`;

export const AppContainer = ({ children, className = "" }) => {
  return (
    <Div justifyCenter className={className}>
      <Container mcontainer>{children}</Container>
    </Div>
  );
};

export const PageTitle = styled(H3)`
  font-weight: 600;
`;

export const PageDescription = styled(Div)`
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${(p) => p.theme.colors.black60};
  margin-top: 12px;
`;
