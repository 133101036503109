const EmailIcon = () => {
  return (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 0C15.5523 0 16 0.447715 16 1V9C16 9.55229 15.5523 10 15 10H1C0.447715 10 0 9.55229 0 9V1C0 0.447715 0.447715 0 1 0H15ZM15 2.892L8 6.00406L1 2.892V9H15V2.892ZM15 1H1V1.798L8 4.90991L15 1.798V1Z"
        fill="#999999"
      />
    </svg>
  );
};

export default EmailIcon;
