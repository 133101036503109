import React, { useEffect, useState } from "react";
import type { DocumentData } from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
} from "firebase/storage";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { addToast } from "@musicaudienceexchange/melodies/Toast";
import { useAppContext } from "AppContextProvider";
import { SelectImageFields } from "components/common/SelectImageFields";
import { getBlobFromUrl } from "utils";
import { Button } from "@musicaudienceexchange/melodies/Button";
import {
  Form as FormBase,
  FormikSumbitButton,
  FormikTextInput,
  yupRequired,
} from "@musicaudienceexchange/melodies/forms";
import { useUser } from "auth";
import { Modal } from "./PasswordForm";
import { Body2, Caption, H3 } from "@musicaudienceexchange/melodies/Text";
import styled from "styled-components";

const getBlobUrl = (userId: string, blob: Blob) =>
  new Promise<string>((resolve, reject) => {
    const storage = getStorage();
    const mimeType = blob.type;
    const ext = mimeType.split(";")[0].split("/")[1];

    const storageRef = ref(
      storage,
      `/user_profile/${userId}/${Math.random()
        .toString(36)
        .substring(2)}.${ext}`
    );

    const task = uploadBytesResumable(storageRef, blob);

    task.on(
      "state_changed",
      (snap) =>
        console.log(
          "progress",
          (snap.bytesTransferred / snap.totalBytes) * 100
        ),
      (error) => {
        console.error("uploadError", error);
        reject(error);
      },
      () => getDownloadURL(task.snapshot.ref).then(resolve)
    );
  });

const imageFields = [
  {
    name: "mobileImg",
    label: "Profile Image",
    aspectRatio: 1,
    dropzoneDescription: "Allowed *.jpeg, *.jpg, *.png",
    width: "400px",
  },
];

const Header = styled(Div)`
  margin: 32px 0;
`;

const Form = styled(FormBase)`
  form {
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`;

const Actions = styled(Div)`
  gap: 12px;
`;

interface ContactInfoFormProps {
  profile: DocumentData;
  setIsEditing: (isEditing: boolean) => unknown;
}

export const ContactInfoForm: React.FC<ContactInfoFormProps> = ({
  profile,
  setIsEditing,
}) => {
  const { setAppIsLoading } = useAppContext();
  const { updateName, updatePhotoURL, user } = useUser();
  const [profileImg, setProfileImg] = useState({
    blob: null,
    isInitialValue: false,
  });

  const {
    name: { firstName, lastName },
  } = profile;
  const fields = [
    {
      label: "First Name",
      name: "firstName",
      validation: yupRequired,
      initialValue: firstName,
    },
    {
      label: "Last Name",
      name: "lastName",
      validation: yupRequired,
      initialValue: lastName,
    },
  ];

  useEffect(() => {
    (async () => {
      if (user.photoURL) {
        const blob = await getBlobFromUrl(profile.photoURL || user.photoURL);
        setProfileImg({ blob, isInitialValue: true });
      }
    })();
  }, []);

  const onSubmitForm = async (values) => {
    setAppIsLoading(true);
    await updateName(values.firstName, values.lastName);
    if (profileImg.blob) {
      const url = await getBlobUrl(user.uid, profileImg.blob);
      await updatePhotoURL(url);
    }
    addToast("Contact information updated.");
    setAppIsLoading(false);
    setIsEditing(false);
  };

  return (
    <Modal
      header="Edit Profile Information"
      isOpen={true}
      onClose={() => setIsEditing(false)}
    >
      <SelectImageFields
        values={{ mobileImg: profileImg }}
        setValues={(v) => setProfileImg(v.mobileImg)}
        fields={imageFields}
      />
      <Header flexColumn alignCenter>
        <H3>Edit Profile Information</H3>
        <Body2>Here you can edit your name and profile photo.</Body2>
      </Header>
      <Form fields={fields} onSubmit={onSubmitForm} flexColumn={true}>
        <FormikTextInput
          label="First Name"
          placeholder="Add first name"
          name="firstName"
        />
        <FormikTextInput
          label="Last Name"
          placeholder="Add last name"
          name="lastName"
        />
        <Actions flexColumn>
          <FormikSumbitButton>Save</FormikSumbitButton>
          <Button variant="secondary" onClick={() => setIsEditing(false)}>
            Cancel
          </Button>
        </Actions>
      </Form>
    </Modal>
  );
};
