import { useEffect, useRef } from "react";
export var getUrl = function (_a) {
    var _b = _a.domain, domain = _b === void 0 ? window.location.origin : _b, _c = _a.path, path = _c === void 0 ? "/" : _c, _d = _a.params, params = _d === void 0 ? {} : _d;
    var url = new URL(path, (domain === null || domain === void 0 ? void 0 : domain.match(/^https?:\/\//i)) ? domain : "https://".concat(domain));
    Object.entries(params).forEach(function (_a) {
        var key = _a[0], value = _a[1];
        url.searchParams.set(key, value);
    });
    return url.toString();
};
export var getParams = function (search) {
    var parsed = new URLSearchParams(search);
    var params = {
        email: parsed.get("email") || "",
        to: parsed.get("to"),
    };
    return params;
};
export function usePrevious(value) {
    var ref = useRef();
    useEffect(function () {
        ref.current = value;
    });
    return ref.current;
}
