import styled from "styled-components";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { Page } from "components/common/Page";
import { useAuthQueryParams, useHistoryPush } from "components/common";
import { sessionApi } from "@musicaudienceexchange/firebase-auth-ui";
import { NewPasswordForm } from "./MyAccount/PasswordForm";
import { ROUTES } from ".";
import { PageTitle } from "components/common/layout";
import { PageIcon } from "components/common/PageIcon";

export const ResetPassword = () => {
  const { redirect } = useAuthQueryParams(["redirect"]);
  const historyPush = useHistoryPush();
  const onComplete = (hasErrors: boolean) => {
    if (!hasErrors) {
      if (redirect) {
        window.location.href = sessionApi.go(decodeURIComponent(redirect));
      } else {
        historyPush(ROUTES.MY_ACCOUNT);
      }
    }
  };

  return (
    <Page showSignout>
      <HeaderIcon dflex justifyCenter mt={24}>
        <PageIcon name="lock" />
      </HeaderIcon>
      <Div mt={20} mb={40} flexColumn alignCenter>
        <PageTitle>Reset Password</PageTitle>
      </Div>
      <FormContainer flexColumn grow>
        <NewPasswordForm setIsEditing={onComplete} isReset />
      </FormContainer>
    </Page>
  );
};

const HeaderIcon = styled(Div)`
  margin-top: 24px;
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 16px;
  }
`;

const FormContainer = styled(Div)`
  width: 100%;
  padding-bottom: 66px;
  ${(p) => p.theme.mediaQueries.mobile} {
    padding-bottom: 28px;
  }
`;
