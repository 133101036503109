import React, { useState } from "react";
import { EmailAuthProvider, reauthenticateWithCredential } from "firebase/auth";

import { Button } from "@musicaudienceexchange/melodies/Button";
import {
  ErrorMessageWarningContainer,
  FormikSumbitButton,
  Form,
  FormikTextInput,
  yupPassword,
} from "@musicaudienceexchange/melodies/forms";
import { Body1, Selected } from "@musicaudienceexchange/melodies/Text";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { Divider } from "components/common";
import { useLogin } from "components/Routes/common/useLogin";

import { useUser } from "auth";

export const RenewCredentialsForm: React.FC<{
  setShouldRenewCredentials: (shouldRenewCredentials: boolean) => unknown;
}> = ({ setShouldRenewCredentials }) => {
  const { user } = useUser();
  const [isInvalidCredential, setIsInvalidCredential] = useState(false);

  const {
    isSubmitButtonDisabled,
    onSubmitForm: onEmailLogin,
    emailSentUI,
  } = useLogin(true);

  const onSubmit = async (values) => {
    const { currentPassword } = values;
    try {
      const credentials = EmailAuthProvider.credential(
        user.email,
        currentPassword
      );

      await reauthenticateWithCredential(user, credentials);
      setIsInvalidCredential(false);
      setShouldRenewCredentials(false);
    } catch (err) {
      setIsInvalidCredential(true);
      console.log(err);
    }
  };

  const currentPasswordFormUI = (
    <>
      <Body1 mt={28} mb={20}>
        Quid ex ea quid percipit aut in ea commodi consequatur? quis nostrum.
      </Body1>
      <Form fields={[currentPassword]} onSubmit={onSubmit}>
        <>
          <FormikTextInput
            withDebounce={false}
            type="password"
            {...currentPassword}
          />
          {isInvalidCredential && (
            <ErrorMessageWarningContainer mt={30}>
              You&apos;ve entered an incorrect password.
            </ErrorMessageWarningContainer>
          )}
          <FormikSumbitButton variant="secondaryPrimary" mt={35}>
            Renew Credentials
          </FormikSumbitButton>
        </>
      </Form>
    </>
  );

  return (
    <Div>
      <Body1 mt={20} mb={20}>
        Quid ex ea quid percipit aut in ea commodi consequatur? quis nostrum.
      </Body1>
      <Button
        onClick={() => onEmailLogin({ email: user.email })}
        isDisabled={isSubmitButtonDisabled}
      >
        Send Email
      </Button>
      {emailSentUI}
      <Div dflex alignCenter mt={28}>
        <Divider />
        <Selected centered ml={10} mr={10}>
          OR
        </Selected>
        <Divider />
      </Div>
      {currentPasswordFormUI}
    </Div>
  );
};

const currentPassword = {
  label: "Current Password",
  name: "currentPassword",
  placeholder: "Enter your email",
  initialValue: "",
  validation: yupPassword,
};
