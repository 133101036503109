import { Div } from "@musicaudienceexchange/melodies/utils";
import { useEffect, useState } from "react";
import styled from "styled-components";
import SelectImageModal from "./SelectImageModal";
import { ReactComponent as IcEdit } from "icons/ic_edit.svg";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import {
  AvatarProps,
  Avatar as AvatarBase,
} from "@musicaudienceexchange/melodies/Avatar";
import { SvgIndividual } from "@musicaudienceexchange/melodies/Svgs/Individual";

type ImgContainerProps = {
  src?: string;
};
const ImgContainer = styled(Div)<ImgContainerProps>`
  box-sizing: border-box;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background: #ffffff;
  border: 1px solid ${(p: any) => p.theme.colors.gray1};
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
`;

const MediaIcon = styled(SvgIndividual)`
  && {
    width: 70%;
    height: 70%;
    path {
      fill: ${(props) => props.theme.colors.black20};
    }
    ${(props) => props.theme.mediaQueries.mobile} {
      width: 22px;
      height: 22px;
    }
  }
`;

const ImageInput = ({ value, onSelect, name, ...props }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imgUrl, setImgUrl] = useState(null);

  useEffect(() => {
    if (value)
      setImgUrl(typeof value === "string" ? value : URL.createObjectURL(value));
  }, [value]);

  let avatarProps: AvatarProps = {
    icon: <MediaIcon />,
    bgColor: colors.gray2,
  };

  return (
    <>
      <SelectImageModal
        isOpen={isModalOpen}
        onSelect={onSelect}
        onClose={() => setIsModalOpen(false)}
        {...props}
      />
      <ImgContainer centered src={imgUrl}>
        <Action onClick={() => setIsModalOpen(true)}>
          <IcEdit />
        </Action>
        {imgUrl ? <Img src={imgUrl} /> : <Avatar {...avatarProps} />}
      </ImgContainer>
    </>
  );
};

export default ImageInput;

const Action = styled(Div)`
  background-color: ${(props) => props.theme.colors.black70};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  width: 32px;
  height: 32px;
  position: absolute;
  right: 3px;
  top: 3px;
  color: #ffffff;
  cursor: pointer;

  ${(props) => props.theme.mediaQueries.mobile} {
    top: -2px;
    right: -2px;
  }
`;

const Avatar = styled(AvatarBase)`
  background-color: #ffffff;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
  width: 145px;
  height: 145px;

  ${(props) => props.theme.mediaQueries.mobile} {
    width: 100px;
    height: 100px;
  }
`;
