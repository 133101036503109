import React from "react";

import { Button } from "@musicaudienceexchange/melodies/Button";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { SvgLeft } from "@musicaudienceexchange/melodies/Svgs/Left";

import { useHistory } from "react-router";
import styled from "styled-components";

import { useUser } from "auth";

const NavigationContainer = styled(Div)`
  color: ${(p) => p.theme.colors.black60};
  background: ${(p) => p.theme.colors.white};
  ${(p) => p.theme.mediaQueries.desktop} {
    padding: 0 34px;
  }
`;

type Props = {
  withBackButton?: boolean;
};

export const Navigation: React.FC<Props> = ({ withBackButton = false }) => {
  const { signOut, isLoggedIn } = useUser();
  const history = useHistory();

  return (
    <NavigationContainer mt={14} spaceBetween alignCenter mcontainer>
      <Div dflex alignCenter ml={-7}>
        {withBackButton && (
          <Div clickable dInline onClick={() => history.goBack()}>
            <SvgLeft />
          </Div>
        )}
      </Div>
      {isLoggedIn && (
        <Button
          tabIndex={-1}
          w={100}
          mr={-10}
          variant="tertiary"
          onClick={() => signOut()}
        >
          Sign Out
        </Button>
      )}
    </NavigationContainer>
  );
};
