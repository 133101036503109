import { getFunctions, httpsCallable } from "firebase/functions";

type APIResponse = { status: "failure" | "success"; message?: string };
type OptOutAction = "create" | "resend" | "verify" | "cancel";

export const requestOptOut = async (email: string): Promise<APIResponse> => {
  try {
    const { data } = await httpsCallable<
      { action: OptOutAction; email: string },
      APIResponse
    >(
      getFunctions(),
      "optOutActions"
    )({
      action: "create",
      email,
    });

    return data;
  } catch (error) {
    console.error(error);
    return { status: "failure", message: "unable to send opt out request" };
  }
};

export const resendOptOutEmail = async (email: string) => {
  try {
    const { data } = await httpsCallable<
      { action: OptOutAction; email: string },
      APIResponse
    >(
      getFunctions(),
      "optOutActions"
    )({ action: "resend", email });

    return data;
  } catch (error) {
    console.error(error);
    return { status: "failure", message: "unable to resend opt out email" };
  }
};

export const verifyOptOut = async (code: string): Promise<APIResponse> => {
  try {
    const { data } = await httpsCallable<
      { action: OptOutAction; code: string },
      APIResponse
    >(
      getFunctions(),
      "optOutActions"
    )({
      action: "verify",
      code,
    });

    return data;
  } catch (error) {
    console.error(error);
    return { status: "failure", message: "unable to verify opt out" };
  }
};

export const cancelOptOut = async (code: string): Promise<APIResponse> => {
  try {
    const { data } = await httpsCallable<
      { action: OptOutAction; code: string },
      APIResponse
    >(
      getFunctions(),
      "optOutActions"
    )({
      action: "cancel",
      code,
    });

    return data;
  } catch (error) {
    console.error(error);
    return { status: "failure", message: "unable to cancel opt out" };
  }
};
