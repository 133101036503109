var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { getAuth, signInWithCustomToken, signInWithEmailLink, signOut, isSignInWithEmailLink, } from "firebase/auth";
var URLActions = ["init", "end", "go", "resume", "login"];
export var API_ROUTE = "/session";
var HttpError = /** @class */ (function (_super) {
    __extends(HttpError, _super);
    function HttpError(error) {
        var _this = _super.call(this, error.message) || this;
        _this.error = error;
        return _this;
    }
    return HttpError;
}(Error));
/**
 * NEVER USE THESE QUERYSTRING PARAMS
 */
var ParamsToRemove = [
    "apiKey",
    "oobCode",
    "continueUrl",
    "languageCode",
    "mode",
    "tenantId",
    "email",
];
var isFirebaseError = function (error) {
    if (typeof error === "object" && error !== null) {
        if ("code" in error && typeof error.code === "string") {
            return true;
        }
    }
    return false;
};
var formatError = function (error, defaultError) {
    if (defaultError === void 0) { defaultError = {
        message: "Internal error",
        status: "INTERNAL",
    }; }
    if (!(error instanceof HttpError)) {
        var keys = Object.getOwnPropertyNames(error || {});
        if (keys.includes("message") && keys.includes("status")) {
            error = new HttpError(error);
        }
        else {
            error = new HttpError(defaultError);
        }
    }
    return error;
};
export var Api;
(function (Api) {
    function url(action, params) {
        var url = new URL(API_ROUTE, window.location.origin);
        url.searchParams.set("action", action);
        Object.entries(params || {}).forEach(function (_a) {
            var key = _a[0], value = _a[1];
            return url.searchParams.set(key, value);
        });
        return url.toString();
    }
    Api.url = url;
    function call(urlOrAction, params) {
        if (params === void 0) { params = {}; }
        return __awaiter(this, void 0, void 0, function () {
            var url;
            var _this = this;
            return __generator(this, function (_a) {
                url = urlOrAction.match(/^https?:\/\//i)
                    ? urlOrAction
                    : Api.url(urlOrAction);
                return [2 /*return*/, fetch(url, __assign({
                        method: "POST",
                        headers: { "content-type": "application/json" },
                    }, params))
                        .then(function (res) { return __awaiter(_this, void 0, void 0, function () {
                        var data;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0: return [4 /*yield*/, res.json().catch(function () { return ({}); })];
                                case 1:
                                    data = _a.sent();
                                    if (res.status >= 400) {
                                        throw formatError(data.error, {
                                            message: res.statusText,
                                            status: "INTERNAL",
                                        });
                                    }
                                    return [2 /*return*/, data];
                            }
                        });
                    }); })
                        .catch(function (error) {
                        throw formatError(error, {
                            message: error.message,
                            status: "INTERNAL",
                        });
                    })];
            });
        });
    }
    Api.call = call;
})(Api || (Api = {}));
export function init(userOrIdToken) {
    return __awaiter(this, void 0, void 0, function () {
        var idToken, _a;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    if (!(typeof userOrIdToken === "string")) return [3 /*break*/, 1];
                    _a = userOrIdToken;
                    return [3 /*break*/, 3];
                case 1: return [4 /*yield*/, (userOrIdToken === null || userOrIdToken === void 0 ? void 0 : userOrIdToken.getIdToken().catch(function () { return ""; }))];
                case 2:
                    _a = _b.sent();
                    _b.label = 3;
                case 3:
                    idToken = _a;
                    if (idToken) {
                        return [2 /*return*/, Api.call("init", {
                                body: JSON.stringify({ idToken: idToken }),
                            })
                                .then(function () { return true; })
                                .catch(function () { return false; })];
                    }
                    return [2 /*return*/, false];
            }
        });
    });
}
/**
 * Removes the session cookie
 */
export var removeCookie = function () { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        Api.call("end")
            .then(function () {
            console.log("session cookies removed");
        })
            .catch(function (error) {
            console.error("Error ending session", error);
        });
        return [2 /*return*/];
    });
}); };
/**
 * Resume the user's session using cookies and optionally a magic link.  If
 * successful, returns the user, null otherwise.  If "search" params are
 * supplied, at attempt will be made to sign-in the user using a magic link.
 * If not supplied, or the attempt fails, then an attempt will be made to
 * sign-in the user using the session cookie.
 *
 * This method does not throw an error.
 */
export var resume = function (opts, firebase) {
    if (opts === void 0) { opts = {}; }
    return __awaiter(void 0, void 0, void 0, function () {
        var auth, user, searchParams, email, parsed, _i, ParamsToRemove_1, key;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    auth = getAuth(firebase);
                    user = null;
                    if (!opts.href) return [3 /*break*/, 2];
                    searchParams = new URL(opts.href).searchParams;
                    if (!isSignInWithEmailLink(auth, opts.href)) return [3 /*break*/, 2];
                    console.log("Found magic link");
                    email = opts.email;
                    if (!email) {
                        email = searchParams.get("email") || "";
                    }
                    if (!email) return [3 /*break*/, 2];
                    parsed = new URL(window.location.href);
                    for (_i = 0, ParamsToRemove_1 = ParamsToRemove; _i < ParamsToRemove_1.length; _i++) {
                        key = ParamsToRemove_1[_i];
                        parsed.searchParams.delete(key);
                    }
                    // Clear out the magic link data from the address bar
                    window.history.replaceState(null, "", parsed.href);
                    return [4 /*yield*/, signInWithEmailLink(auth, email, opts.href)
                            .then(function (_a) {
                            var user = _a.user;
                            return user;
                        })
                            .catch(function (error) { return __awaiter(void 0, void 0, void 0, function () {
                            return __generator(this, function (_a) {
                                switch (_a.label) {
                                    case 0:
                                        console.error("Error with magic link", error);
                                        if (!(isFirebaseError(error) && opts.firebaseError)) return [3 /*break*/, 2];
                                        return [4 /*yield*/, opts.firebaseError(error)];
                                    case 1:
                                        _a.sent();
                                        _a.label = 2;
                                    case 2: return [2 /*return*/, null];
                                }
                            });
                        }); })];
                case 1:
                    user = _a.sent();
                    _a.label = 2;
                case 2:
                    if (!!user) return [3 /*break*/, 4];
                    return [4 /*yield*/, Api.call("resume")
                            .then(function (data) {
                            var token = data.token;
                            if (token === "signout") {
                                return signOut(auth).then(function () { return null; });
                            }
                            else if (token) {
                                return signInWithCustomToken(auth, data.token)
                                    .then(function (_a) {
                                    var user = _a.user;
                                    return user;
                                })
                                    .catch(function (error) {
                                    console.error("Error signing in with token", error);
                                    return null;
                                });
                            }
                            return null;
                        })
                            .catch(function () { return null; })];
                case 3:
                    user = _a.sent();
                    _a.label = 4;
                case 4: return [2 /*return*/, user];
            }
        });
    });
};
/**
 * Send a login link to the user's email.  If this returns false, the request
 * did not succeed.  This can happen if the email address is invalid OR if
 * are too many successive requests with the same email address.  This method
 * should not be called more than once per 10 seconds.
 *
 * This method does not throw an error.
 */
export var login = function (email, to) { return __awaiter(void 0, void 0, void 0, function () {
    return __generator(this, function (_a) {
        return [2 /*return*/, Api.call("login", {
                body: JSON.stringify({ email: email, to: to ? to : window.location.origin }),
            })
                .then(function () { return true; })
                .catch(function () { return false; })];
    });
}); };
/**
 * Generate a URL that can be used to auto-negotiate sign-in for the current
 * user, possibly at a different domain.
 */
export var go = function (url) {
    return Api.url("go", { url: url });
};
