import { FC, PropsWithChildren } from "react";
import { useUser } from "auth";

export const AuthHandler: FC<PropsWithChildren<{ children?: any }>> = ({
  children,
}) => {
  const { user, signOut } = useUser();

  if (
    user &&
    (user.isAnonymous || (user.email === null && user.phoneNumber === null))
  ) {
    signOut(false);
  }

  return children;
};
