import { Div } from "@musicaudienceexchange/melodies/utils";
import React from "react";
import { useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

const DefaultWrapper = ({ children }) => {
  return <Div>{children}</Div>;
};

const ImageCrop = ({
  srcImg,
  options = {},
  onCrop = null,
  Wrapper = DefaultWrapper,
  style = {},
}) => {
  const imageRef = useRef(null);
  const [crop, setCrop] = useState({
    unit: "%",
    width: 70,
    x: 15,
    y: 15,
    aspect: 16 / 9,
    ...options,
  });

  const onCropEnd = (_crop) => {
    const image = imageRef.current;
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = _crop.width * scaleX;
    canvas.height = _crop.height * scaleY;
    const ctx = canvas.getContext("2d");
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    ctx.drawImage(
      image,
      _crop.x * scaleX,
      _crop.y * scaleY,
      _crop.width * scaleX,
      _crop.height * scaleY,
      0,
      0,
      _crop.width * scaleX,
      _crop.height * scaleY
    );

    canvas.toBlob((blob) => {
      onCrop(blob);
    }, "image/jpeg");
  };

  return (
    <Wrapper>
      <ReactCrop
        src={srcImg}
        crop={crop}
        onImageLoaded={(img) => (imageRef.current = img)}
        onChange={(c) => setCrop(c)}
        onComplete={(c) => onCropEnd(c)}
        style={style}
        imageStyle={style}
      />
    </Wrapper>
  );
};

export default ImageCrop;
