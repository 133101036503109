import { usePlatform } from "hooks";
import welcomeSetlive from "Illustrations/welcome.png";
import gearSetlive from "Illustrations/gear.png";
import emailSetlive from "Illustrations/email.png";
import lockSetlive from "Illustrations/lock.png";
import welcomeNextsong from "Illustrations/welcome-nextsong.png";
import gearNextsong from "Illustrations/gear-nextsong.png";
import emailNextsong from "Illustrations/email-nextsong.png";
import lockNextsong from "Illustrations/lock-nextsong.png";
import styled from "styled-components";

interface PageIconProps {
  name: "welcome" | "lock" | "email" | "gear";
}

export const PageIcon = ({ name }: PageIconProps) => {
  const assets = {
    setlive: {
      welcome: welcomeSetlive,
      email: emailSetlive,
      lock: lockSetlive,
      gear: gearSetlive,
    },
    nextsong: {
      welcome: welcomeNextsong,
      email: emailNextsong,
      lock: lockNextsong,
      gear: gearNextsong,
    },
  };
  const platform = usePlatform();
  const isNextsong = platform === "nextsong";
  const imagePath = assets[isNextsong ? "nextsong" : "setlive"][name];
  return <IllustrationImage src={imagePath} />;
};

const IllustrationImage = styled.img`
  width: 178px;
  height: 170px;
`;
