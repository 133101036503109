import { SvgUpload } from "@musicaudienceexchange/melodies/Svgs/Upload";
import { Subtitle1, Subtitle2 } from "@musicaudienceexchange/melodies/Text";
import { colors } from "@musicaudienceexchange/melodies/Theme/colors";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { happyCloudSvg, sadCloudSvg } from "./svgs";

const contentOptions = {
  inactive: {
    message: "Drag and drop your image",
    icon: (
      <Div c={colors.black20}>
        <SvgUpload width="50px" height="50px" />
      </Div>
    ),
  },
  active: {
    valid: {
      message: "Drop It!",
      icon: happyCloudSvg,
    },
    invalid: {
      message: "Are you sure this is an image?",
      icon: sadCloudSvg,
    },
  },
};

const DefaultWrapper = ({ children }) => {
  return <Div>{children}</Div>;
};

const ImageDropzone = ({
  onSelect,
  placeholderText = "",
  Wrapper = DefaultWrapper,
}) => {
  const dzProps = useDropzone({
    accept: "image/*",
    onDrop: ([file]) => {
      if (file) {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => onSelect(reader.result);
      }
    },
  });
  const { getRootProps, getInputProps, isDragActive, isDragAccept } = dzProps;

  const content = !isDragActive
    ? contentOptions.inactive
    : contentOptions.active[isDragAccept ? "valid" : "invalid"];

  return (
    <Div c={colors.black80} clickable fs={17} textAlignCenter>
      <Wrapper {...getRootProps()}>
        <input {...getInputProps()} />
        <Div>
          <Div centered mt={-10}>
            {content.icon}
          </Div>
          <Message bold mt={10}>
            {content.message}
          </Message>
          {!isDragActive && (
            <Message dflex centered>
              or
              <Div ml={5} underline bold c={colors.navy}>
                click to browse
              </Div>
            </Message>
          )}
          <Div centered mt={10}>
            <Subtitle2 lite fs={12} c={colors.black60}>
              {placeholderText}
            </Subtitle2>
          </Div>
        </Div>
      </Wrapper>
    </Div>
  );
};

export default ImageDropzone;

const Message = styled(Subtitle1)``;
