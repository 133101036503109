import { useWindowInnerSize } from "hooks";

const HeaderBG = ({ isMobile }: { isMobile: boolean }) => {
  const { width } = useWindowInnerSize();

  return isMobile ? (
    <svg
      width={width}
      height="164"
      viewBox={`0 0 ${width} 164`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={`${width}px`}
        height="163.841"
        fill="url(#paint0_linear_1_1807)"
      />
      <path
        d="M0 0H71.803L146.217 163.841H0V0Z"
        fill="url(#paint1_linear_1_1807)"
      />
      <path
        d="M0 163.841H71.803L146.217 -9.72152e-05L0 -9.72152e-05L0 163.841Z"
        fill="url(#paint2_linear_1_1807)"
      />
      <path
        d="M72.7821 0H0V163.841H72.7821L109.01 80.9416L72.7821 0Z"
        fill="#1B0076"
        fillOpacity="0.81"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1_1807"
          x1="-172.98"
          y1="82.2471"
          x2="391"
          y2="82.2471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B0076" />
          <stop offset="1" stopColor="#E71E3D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1_1807"
          x1="0"
          y1="82.2471"
          x2="199.417"
          y2="82.2471"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B0076" />
          <stop offset="1" stopColor="#E71E3D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1_1807"
          x1="-9.87948e-09"
          y1="82.247"
          x2="161.557"
          y2="81.5942"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.0001" stopColor="#1B0076" />
          <stop offset="1" stopColor="#E71E3D" />
        </linearGradient>
      </defs>
    </svg>
  ) : (
    <svg
      width={width}
      height="227"
      viewBox={`0 0 ${width} 227`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={`${width}px`}
        height="227"
        fill="url(#paint0_linear_5_1215)"
      />
      <path
        d="M0 0H109.776L245 227H0V0Z"
        fill="url(#paint1_linear_5_1215)"
        fillOpacity="0.3"
      />
      <path
        d="M0 227H110L242 0L0 0L0 227Z"
        fill="url(#paint2_linear_5_1215)"
        fillOpacity="0.3"
      />
      <defs>
        <linearGradient
          id="paint0_linear_5_1215"
          x1="309.5"
          y1="113"
          x2="1442"
          y2="113.952"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1B0076" />
          <stop offset="1" stopColor="#E71E3D" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_5_1215"
          x1="122"
          y1="113"
          x2="245"
          y2="113"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E71E3D" />
          <stop offset="1" stopColor="#8A1057" />
          <stop offset="1" stopColor="#E71E3D" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_5_1215"
          x1="228"
          y1="133.5"
          x2="1.71876e-06"
          y2="133.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#E71E3D" />
          <stop offset="1" stopColor="#E71E3D" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default HeaderBG;
