import styled, { css } from "styled-components";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { Page } from "components/common/Page";
import {
  Body1,
  Body2,
  Caption,
  Selected,
} from "@musicaudienceexchange/melodies/Text";
import { TextInput } from "@musicaudienceexchange/melodies/TextInput";
import { useEffect, useState } from "react";
import { Checkbox as CheckboxBase } from "@musicaudienceexchange/melodies/Selectable";
import { MaxButton } from "@musicaudienceexchange/melodies/Button";
import { useAppContext } from "AppContextProvider";
import { addToast } from "@musicaudienceexchange/melodies/Toast";
import { useUser } from "auth";
import { ROUTES } from "..";
import { deleteField, doc, DocumentData, updateDoc } from "firebase/firestore";
import { useFirebase } from "providers/FirebaseProvider";
import { useHistoryPush, yupStrictPassword } from "components/common";
import * as yup from "yup";
import { Link } from "@musicaudienceexchange/melodies/Link";
import { PageTitle } from "components/common/layout";
import { PageIcon } from "components/common/PageIcon";
import { usePlatform } from "hooks";

interface FormProps {
  password: string;
  termsAgreement: boolean;
}

interface FormErrorProps {
  password?: string;
  termsAgreement?: string;
}

const formSchema = yup.object({
  password: yupStrictPassword,
  termsAgreement: yup.boolean().isTrue(),
});

export const UpdatePassword = ({ profile }: { profile?: DocumentData }) => {
  const [error, setError] = useState<FormErrorProps>({});
  const { setAppIsLoading, userName } = useAppContext();
  const { user, updateName, updatePassword } = useUser();
  const navigate = useHistoryPush();
  const [form, setForm] = useState<FormProps>({
    password: "",
    termsAgreement: false,
  });
  const { firestore } = useFirebase();
  const platform = usePlatform();
  const isNextsong = platform === "nextsong";

  useEffect(() => {
    if (!userName?.firstName || !userName?.lastName) {
      navigate(ROUTES.accountSetup.name, undefined, true);
    }
  }, []);

  const handleRemoveFlag = async () => {
    try {
      await updateDoc(doc(firestore, `profiles/${user?.uid}`), {
        requiresOnboarding: deleteField(),
      });
    } catch (error) {
      console.error(error);
    }
  };

  const validate = async (schema, data) => {
    try {
      await schema.validate(data, { abortEarly: false });
      return null;
    } catch (err) {
      const errors = err?.inner?.reduce(
        (obj, value) => ({
          ...obj,
          [value.path]: value.message,
        }),
        {}
      );
      return errors;
    }
  };

  const handleSubmit = async (
    e:
      | React.MouseEvent<HTMLButtonElement, MouseEvent>
      | React.KeyboardEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    const errors = await validate(formSchema, form);
    if (!errors) {
      setAppIsLoading(true);
      try {
        const { firstName, lastName } = userName;
        await updateName(firstName, lastName);

        await updatePassword(form.password);

        if (profile?.requiresOnboarding) {
          await handleRemoveFlag();
        }

        addToast("Account updated.");
        navigate(ROUTES.MY_ACCOUNT, { disableBackNavigation: true }, true);
      } catch (err) {
        console.error(err);
      }
      setAppIsLoading(false);
    } else {
      setError(errors);
    }
  };

  const onChange = (name: keyof FormProps, value: string | boolean) => {
    if (error[name]) delete error[name];
    setError(error);
    setForm({ ...form, [name]: value });
  };

  return (
    <Page
      onBackNavigate={() => navigate(ROUTES.accountSetup.name, undefined, true)}
      showSignout
    >
      <HeaderIcon dflex justifyCenter mt={24}>
        <PageIcon name="lock" />
      </HeaderIcon>
      <Header mt={20} mb={72} flexColumn alignCenter>
        <PageTitle>Set A Password</PageTitle>
      </Header>
      <FormContainer flexColumn grow>
        <TextInput
          label="Password"
          placeholder="Password"
          type="password"
          value={form.password}
          onChange={(v) => onChange("password", v)}
          hasError={!!error?.password}
        />
        <Caption mt={8}>
          Password must contain at least 8 characters, one uppercase, one
          lowercase, and one number or special character, (e.g., ! @ # $ % ^ &
          *).
        </Caption>
        {!!error?.password && (
          <Label mt={12} error>
            {error?.password}
          </Label>
        )}
        <Div mt={24} mb={16} dflex as="label">
          <Checkbox
            value={form.termsAgreement}
            onChange={() => onChange("termsAgreement", !form.termsAgreement)}
            error={!!error?.termsAgreement}
          />
          <Label
            onClick={() => onChange("termsAgreement", !form.termsAgreement)}
            ml={12}
          >
            I am at least 13 years of age and agree to the{" "}
            {isNextsong ? "NextSong" : "Set.Live"}{" "}
            <Link
              as="a"
              href={
                isNextsong
                  ? "https://nextsong.live/terms"
                  : "https://set.live/terms"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              terms of use
            </Link>{" "}
            and{" "}
            <Link
              as="a"
              href={
                isNextsong
                  ? "https://nextsong.live/privacy"
                  : "https://set.live/privacy"
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </Link>
            .
          </Label>
        </Div>
        <Button
          isDisabled={!form.password || !form.termsAgreement}
          onClick={(e) => handleSubmit(e)}
          onKeyDown={(e) => {
            if (e.code === "Enter") {
              handleSubmit(e);
            }
          }}
          tabIndex={0}
        >
          Activate Account
        </Button>
      </FormContainer>
    </Page>
  );
};

const HeaderIcon = styled(Div)`
  margin-top: 24px;
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 16px;
  }
`;

const Header = styled(Div)`
  ${(p) => p.theme.mediaQueries.mobile} {
    margin-top: 0;
    margin-bottom: 56px;
  }
`;

const Button = styled(MaxButton)`
  &:focus {
    box-shadow: 0 4px 12px 0 rgba(0, 0, 0, 0.2);
  }
`;

const Label = styled(Body2)<{ error?: boolean }>`
  color: #666666;
  font-size: 12px;
  line-height: 18px;
  ${Link} {
    display: inline-block;
    font-size: 12px;
    line-height: 18px;
  }
  ${(props) =>
    props.error &&
    css`
      color: ${(props) => props.theme.colors.error};
      font-weight: 500;
    `};
`;

const Checkbox = styled(CheckboxBase)<{ error?: boolean }>`
  width: auto;
  label {
    height: auto;
  }
  ${Body1}, ${Selected} {
    font-size: 12px;
    font-weight: 400;
    line-height: 18px;
    margin-left: 2px;
    margin-top: 0;
  }
  ${(props) =>
    props.error &&
    css`
      & > div > div > div {
        border-color: ${(props) => props.theme.colors.error};
        border-width: 2px;
      }
    `};
`;

const FormContainer = styled(Div)`
  padding-bottom: 66px;
  ${(p) => p.theme.mediaQueries.mobile} {
    padding-bottom: 28px;
  }
`;
