import { useIsMobile } from "hooks";

const CheckIcon = ({ height }: { height?: number }) => {
  const isMobile = useIsMobile();

  return (
    <svg
      width={height ?? isMobile ? 30 : 71}
      height={height ?? isMobile ? 30 : 71}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.0001 0.600098C22.953 0.600098 29.4001 7.0472 29.4001 15.0001C29.4001 22.953 22.953 29.4001 15.0001 29.4001C7.0472 29.4001 0.600098 22.953 0.600098 15.0001C0.600098 7.0472 7.0472 0.600098 15.0001 0.600098ZM24.3467 7.6626C23.6847 7.02711 22.6629 6.99804 21.9681 7.56137L21.8016 7.71454L12.3253 17.5849L8.22275 13.1402L8.0593 12.9838C7.37568 12.407 6.35349 12.416 5.6792 13.0385C5.00492 13.6609 4.91423 14.6791 5.43462 15.4066L5.57746 15.582L12.2744 22.837L24.3986 10.2077L24.5449 10.0351C25.0794 9.31788 25.0086 8.2981 24.3467 7.6626Z"
        fill="black"
      />
    </svg>
  );
};

export default CheckIcon;
