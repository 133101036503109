import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Button } from "@musicaudienceexchange/melodies/Button";
import {
  Modal as ModalBase,
  ModalCloseIcon,
  ModalContainer,
  ModalWrapper,
} from "@musicaudienceexchange/melodies/Modal";
import { SvgKey } from "@musicaudienceexchange/melodies/Svgs/Key";
import { Caption, H2 } from "@musicaudienceexchange/melodies/Text";
import { addToast } from "@musicaudienceexchange/melodies/Toast";
import {
  Form,
  FormikSumbitButton,
  FormikTextInput,
  yupPasswordConfirmation,
} from "@musicaudienceexchange/melodies/forms";
import { Div } from "@musicaudienceexchange/melodies/utils";
import { useAppContext } from "AppContextProvider";
import { useUser } from "auth";
import { yupStrictPassword } from "components/common";
import { Title } from "components/common/PageLayout";
import { RenewCredentialsForm } from "./RenewCredentialsForm";

const fields = [
  {
    label: "Password",
    name: "password",
    validation: yupStrictPassword,
    initialValue: "",
  },
  {
    label: "Confirm Password",
    name: "confirmPassword",
    validation: yupPasswordConfirmation,
    initialValue: "",
  },
];

interface NewPasswordFormProps {
  setIsEditing: (isEditing: boolean) => unknown;
  isReset?: boolean;
}

export const NewPasswordFormModal = ({ setIsEditing }) => {
  return (
    <Modal
      header="Update Password"
      isOpen={true}
      onClose={() => setIsEditing(false)}
    >
      <NewPasswordForm setIsEditing={setIsEditing} />
    </Modal>
  );
};

const Actions = styled(Div)`
  gap: 12px;
`;

export const NewPasswordForm: React.FC<NewPasswordFormProps> = ({
  setIsEditing,
  isReset,
}) => {
  const { setAppIsLoading } = useAppContext();
  const [shouldRenewCredentials, setShouldRenewCredentials] = useState(false);
  const { updatePassword } = useUser();

  const onSubmitForm = async (values) => {
    const { password } = values;
    try {
      setAppIsLoading(true);
      await updatePassword(password);
      addToast("Password updated.");
      onClose();
    } catch (err) {
      setShouldRenewCredentials(true);
    }
    setAppIsLoading(false);
  };

  const onClose = () => setIsEditing(false);

  return shouldRenewCredentials ? (
    <RenewCredentialsForm
      setShouldRenewCredentials={setShouldRenewCredentials}
    />
  ) : (
    <UpdatePasswordForm {...{ onSubmitForm, onClose, isReset }} />
  );
};

const UpdatePasswordForm = ({ onSubmitForm, onClose, isReset }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    setTimeout(() => {
      inputRef?.current?.getElementsByTagName("input")[0]?.focus();
    }, 10);
  }, []);

  return (
    <Form fields={fields} onSubmit={onSubmitForm}>
      {!isReset && (
        <Title size={26} mb={26}>
          Update Password
        </Title>
      )}
      <Div ref={inputRef} tabIndex={0}>
        <FormikTextInput
          label="New Password"
          leftIcon={<SvgKey />}
          type="password"
          name="password"
          placeholder="Choose a new password..."
        />
      </Div>
      <Caption mt={10}>
        Password must contain at least 8 characters, one uppercase, one
        lowercase, and one number or special character, (e.g., ! @ # $ % ^ & *).
      </Caption>
      <Div mt={18}>
        <FormikTextInput
          label="Confirm Password"
          leftIcon={<SvgKey />}
          type="password"
          name="confirmPassword"
          placeholder="Passwords must match..."
        />
      </Div>
      <Actions mt={30} flexColumn>
        <FormikSumbitButton>Save</FormikSumbitButton>
        {!isReset && (
          <Button variant="secondary" onClick={() => onClose(false)}>
            Cancel
          </Button>
        )}
      </Actions>
    </Form>
  );
};

export const Modal = styled(ModalBase)`
  ${ModalContainer} {
    ${(p) => p.theme.mediaQueries.mobile} {
      padding-left: 24px;
      padding-right: 24px;
    }
    ${(p) => p.theme.mediaQueries.desktop} {
      margin-top: 0;
      justify-content: center;
    }
  }
  ${ModalWrapper} {
    border-radius: 20px;
    padding: 48px 40px;
    width: 100%;

    ${(p) => p.theme.mediaQueries.desktop} {
      margin-bottom: 0;
      width: 480px;
    }

    ${H2}, ${ModalCloseIcon} {
      display: none;
    }
  }
`;
